<template>
  <div class="login-container">
    <div class="login-card">
      <h2 class="login-title fw-bold">Prisijungti</h2>
      <div v-if="showSuccessMessage" class="success-message">
  Prisijungimas sėkmingas! Nukreipiame į paskyrą...
</div>
      <form @submit.prevent="handleSubmit">
        <div class="form-group">
          <label for="email">El. pašto adresas</label>
          <input type="email" id="email" v-model="email" required />
        </div>
        <div class="form-group">
          <label for="password">Slaptažodis</label>
          <input type="password" id="password" v-model="password" required />
        </div>
        <button type="submit" class="login-btn">Prisijungti</button>
        <p class="register-link-text">
          Dar neturi paskyros?
          <a class="register-link" @click="goToRegisterPage">Registruotis</a>
        </p>
        <!-- <p v-if="error" class="error-message">{{ error }}</p> -->
        <p v-if="feedbackMessage" :class="feedbackClass">{{ feedbackMessage }}</p>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VueJwtDecode from "vue-jwt-decode";
import EventBus from "@/eventBus";
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

export default {
  name: "loginPageComponent",
  data() {
    return {
      email: "",
      password: "",
      feedbackMessage: "", // Message to display
      feedbackType: "", // Type: 'success' or 'error'
    };
  },
  computed: {
    feedbackClass() {
      return this.feedbackType === "success" ? "success-message" : "error-message";
    },
  },
  methods: {
    handleSubmit() {
      this.login();
    },
    login() {
      axios
        .post(`${API_BASE_URL}/api/login`, {
          email: this.email,
          password: this.password,
        })
        .then((response) => {
          const token = response.data.token;
          const decodedToken = VueJwtDecode.decode(token);

          const roles = decodedToken.roles;
          if (roles.includes("ROLE_USER")) {
            this.feedbackType = "success"; // Set feedback type to success
            this.feedbackMessage = "Prisijungimas sėkmingas! Nukreipiame į paskyrą...";
            setTimeout(() => {
              localStorage.setItem("userToken", token);
              localStorage.removeItem("sessionId", token);
              EventBus.emit("loginSuccess");
              EventBus.emit("cartUpdated");
              this.$router.push({ name: "userProfile" });
              // this.mergeGuestCart();
            }, 1000); // Wait for 1 second before redirecting
          } else {
            this.feedbackType = "error"; // Set feedback type to error
            this.feedbackMessage = "Access Denied: user permitted only.";
          }
        })
        .catch(() => {
          this.feedbackType = "error"; // Set feedback type to error
          this.feedbackMessage = "Neteisingas el. pašto adresas arba slaptažodis";
        });
    },
    goToRegisterPage() {
      this.$router.push({ name: "Register" });
    },
    mergeGuestCart() {
      const guestCart = JSON.parse(localStorage.getItem("guestCart"));

      if (guestCart && guestCart.length > 0) {
        const token = localStorage.getItem("userToken");

        guestCart.forEach((item) => {
          axios
            .post(
              `${API_BASE_URL}/user/add-to-cart/${item.id}`,
              {},
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then(() => {
              console.log(`Product ${item.id} added to user cart.`);
            })
            .catch((error) => {
              console.error(`Error adding product ${item.id} to user cart:`, error);
            });
        });

        localStorage.removeItem("guestCart");
        this.$router.push({ name: "userProfile" });
      } else {
        this.$router.push({ name: "userProfile" });
      }
    },
  },
  mounted() {
    // Scroll to top when component is rendered
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  },
};
</script>

<style lang="scss" scoped>
@import "/src/styles/variable";

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 2rem 1rem;
  background-color: #f4f7fa;
}

.login-card {
  background-color: #fff;
  padding: 2.5rem;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.login-title {
  margin-bottom: 1.5rem;
  font-size: 1.8rem;
  text-align: left;
}

.form-group {
  margin-bottom: 1.5rem;
  text-align: left;

  label {
    display: block;
    margin-bottom: 0.4rem;
    color: $foreground-color;
    font-size: 1rem;
  }

  input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid $border-color;
    border-radius: 6px;
    background-color: #f9fafb;
    color: $foreground-color;
    font-size: 0.95rem;

    &:focus {
      outline: none;
      border-color: $ring-color;
      box-shadow: 0 0 0 3px fade-out($ring-color, 0.7);
    }
  }
}

.login-btn {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  font-weight: bold;
  background-color: $primary-revolt-color;
  color: #fff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    background-color: darken($primary-revolt-color, 8%);
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
  }
}

.register-link-text {
  margin-top: 1rem;
  color: black;
  font-size: 0.9rem;
}

.register-link {
  color: $primary-revolt-color;
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;

  &:hover {
    color: darken($primary-revolt-color, 10%);
    text-decoration: underline;
  }
}

.error-message {
  color: #d9534f;
  font-size: 0.9rem;
  margin-top: 1rem;
}

.success-message {
  color: #4caf50; // Green for success
  font-size: 0.9rem;
  margin-top: 1rem;
}

.error-message {
  color: #d9534f; // Red for errors
  font-size: 0.9rem;
  margin-top: 1rem;
}

</style>
