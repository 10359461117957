<template>
  <header class="header-background">
    <!-- <div style="display: flex; max-width: 960px; align-items: flex-end; justify-content: flex-end; margin: auto;">
      <div style="margin-bottom: -10px;" class="phone-container d-none d-md-flex align-items-center">
        <i class="fas fa-phone-alt phone-icon"></i>
        <a href="tel:+37060844230" class="phone-number">+370 608 44230</a>
      </div>
    </div> -->
    <div class="header-inner d-flex justify-content-between align-items-center">
      <div class="mobile-icons d-flex d-md-none align-items-center px-3" style="width: 30%;">
        <button class="header-icon" @click="toggleMobileSidebar">
          <i class="fas fa-bars"></i>
        </button>
      </div>
      <a href="/" class="logo-container">
        <img class="logo" :src="logoUrl" alt="ReVolt Logo" />
      </a>
      <div class="search-container d-none d-md-flex align-items-center">
        <div class="search-wrapper">
          <input type="text" placeholder="Įrašykite, ko ieškote. Pvz., klaviaturos" class="search-input"
            v-model="searchTerm" @keydown.enter="searchProducts" />
          <button class="search-btn" @click="searchProducts">
            <i class="fas fa-search"></i>
          </button>
        </div>
      </div>
      <div class="header-btn-group d-none d-md-flex align-items-center">
        <div v-if="isLoggedIn" class="profile-dropdown">
          <button class="header-btn" @click="goToProfile">
            <i class="fas fa-user"></i> Mano Paskyra
          </button>
          <div class="dropdown-menu">
            <button @click="goToMyOrders">Mano Užsakymai</button>
            <button @click="goToFavoriteProducts">Patikusios prekės</button>
            <button @click="logout">Atsijungti</button>
          </div>
        </div>
        <button v-if="!isLoggedIn" class="header-btn" @click="goToLogin">
          <i class="fas fa-sign-in-alt"></i> Prisijungti
        </button>
        <button v-if="!isLoggedIn" class="header-btn register-btn" @click="goToRegister">
          <i class="fas fa-user-plus"></i> Registruotis
        </button>
        <!-- Cart button always visible -->
        <button class="header-btn cart-btn" @click="goToCart">
          <i class="fas fa-shopping-cart"></i> Krepšelis
          <span v-if="cartItemCount > 0" class="cart-badge">{{ cartItemCount }}</span>
        </button>
      </div>
      <!-- Mobile Icons: Search and Cart -->
      <div class="mobile-icons d-flex d-md-none align-items-center px-3" style="margin-left: 2rem;">
        <button class="header-icon" @click="toggleSearchBarMobile">
          <i class="fas fa-search"></i>
        </button>
        <button class="header-icon cart-mobile-btn" @click="goToCart">
          <i class="fas fa-shopping-cart"></i>
          <span v-if="cartItemCount > 0" class="cart-badge">{{ cartItemCount }}</span>
        </button>
      </div>
    </div>

    <!-- Mobile Search Bar -->
    <div ref="mobSearchBar" class="mobile-search d-none">
      <input type="text" placeholder="Įrašykite, ko ieškote. Pvz., treniruokliai" class="search-input"
        v-model="searchTerm" @keydown.enter="searchProducts" />
    </div>
    <!-- SideBarMobileComponent for Mobile Navigation -->
    <SideBarMobileComponent ref="mobileSidebar" />
    <div class="sales-banner">Nemokamas siuntimas visoje Lietuvoje!</div>
  </header>
</template>

<script>
import EventBus from '@/eventBus';
import VueJwtDecode from "vue-jwt-decode";
import axios from 'axios';
import SideBarMobileComponent from '../homePage/SideBarMobileComponent.vue';
import getAuthHeaders from '@/utility/sessionId';

const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

export default {
  name: "HeaderComponent",
  components: {
    SideBarMobileComponent
  },
  data() {
    return {
      isLoggedIn: false,
      logoUrl: require("@/assets/revolot_logo_with_slogan.png"),
      searchTerm: "",
      showSidebar: false,
      cartItemCount: 0,
    };
  },
  mounted() {
    this.checkLoginStatus();
    this.fetchCartItemCount();
    EventBus.on('cartUpdated', this.fetchCartItemCount);
    EventBus.on('loginSuccess', this.handleLoginSuccess);
  },
  methods: {
    checkLoginStatus() {
      const token = localStorage.getItem("userToken");
      if (token) {
        try {
          const decodedToken = VueJwtDecode.decode(token);
          if (decodedToken.roles && decodedToken.roles.includes("ROLE_USER")) {
            this.isLoggedIn = true;
          }
        } catch (e) {
          console.error("Invalid token");
        }
      }
    },
    toggleMobileSidebar() {
      this.$refs.mobileSidebar.toggleSideMenu();
    },
    handleLoginSuccess() {
      this.isLoggedIn = true;
    },
    toggleSearchBarMobile() {
      const searchBar = this.$refs.mobSearchBar;
      searchBar.classList.toggle("d-none");
    },
    goToLogin() {
      this.$router.push({ name: "Login" });
    },
    goToRegister() {
      this.$router.push({ name: "Register" });
    },
    goToProfile() {
      this.$router.push({ name: "userProfile" });
    },
    goToCart() {
      this.$router.push({ name: "Cart" });
    },
    logout() {
      localStorage.removeItem("userToken");
      this.isLoggedIn = false;
      this.$router.push({ name: "Login" });
      EventBus.emit("cartUpdated");
    },
    goToFavoriteProducts() {
      this.$router.push({
        name: "userProfile",
        params: { selectedTab: "favorites" }
      });
    },
    goHome() {
      this.$router.push({
        name: "Home"
      });
    },
    goToMyOrders() {
      this.$router.push({
        name: "userProfile",
        params: { selectedTab: "orders" }
      });
    },
    searchProducts() {
      if (this.searchTerm.trim() === "") {
        EventBus.emit("searchResults", false);
      }
      axios.get(`${API_BASE_URL}/products/search`, { params: { query: this.searchTerm } })
        .then(response => {
          EventBus.emit("searchResults", response.data.products);
        })
        .catch(error => {
          console.error("Error fetching search results:", error);
        });
    },
    fetchCartItemCount() {
      const headers = getAuthHeaders()
      axios
        .get(`${API_BASE_URL}/cart/count`, { headers })
        .then((response) => {
          this.cartItemCount = response.data.count || 0;
        })
        .catch((error) => {
          console.error("Error fetching cart item count:", error);
          this.cartItemCount = 0;
        });
    },
  },
  beforeUnmount() {
    EventBus.off('loginSuccess', this.handleLoginSuccess);
  },
};
</script>

<style lang="scss" scoped>
@import "/src/styles/main.scss";

.header-background {
  background-color: $primary-revolt-color;
  width: 100%;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  position: sticky;
  /* Makes the header sticky */
  top: 0;
  /* Ensures it sticks to the top of the viewport */
  z-index: 1000;
  /* Ensures the header appears above other elements */

  @media (min-width: 768px) {
    // Adjust breakpoint as needed
    padding: 7px 0;
    padding-bottom: 0px;
  }

  @media (max-width: 767px) {
    padding: 0;
    // padding-bottom: 3px;
  }

}

.phone-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  /* Space between the icon and the number */
}

.phone-icon {
  color: white;
  font-size: 1.2rem;
  /* Adjust icon size */
}

.phone-number {
  font-size: 1rem;
  color: white;
  /* Matches other header text */
  text-decoration: none;
  /* Removes underline */
  font-weight: bold;
  transition: color 0.3s ease;
}

.phone-number:hover {
  color: #ffffffb3;
  /* Slightly lighter white on hover */
}


.header-inner {
  max-width: 960px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-container {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.logo {
  max-height: 70px;
}

.search-container {
  flex-grow: 1;
  margin: 0 2rem;
}

.search-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;

  .search-input {
    width: 100%;
    padding: 0.75rem 1rem;
    padding-right: 3rem;
    border-radius: 25px;
    border: none;
    background-color: #f3f4f6;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
    font-size: 1rem;

    &:focus {
      outline: none;
      box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
    }
  }

  .search-btn {
    position: absolute;
    right: 1rem;
    background: none;
    border: none;
    color: #207895;
    font-size: 1.2rem;
    cursor: pointer;
    transition: color 0.3s ease;

    &:hover {
      color: darken(#207895, 10%);
    }
  }
}

.header-btn-group {
  display: flex;
  gap: 1rem;
}

.header-btn {
  padding: 0.6rem 1.2rem;
  font-size: 1rem;
  font-weight: 500;
  background-color: white;
  color: $primary-revolt-color;
  border-radius: 25px;
  border: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.3s ease;

  i {
    font-size: 1rem;
  }

  &:hover {
    background-color: lighten(white, 5%);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }

  &:active {
    background-color: darken(white, 10%);
    transform: scale(0.98);
  }
}

.cart-btn {
  background-color: #207895;
  color: white;

  &:hover {
    background-color: lighten(#207895, 5%);
  }

  &:active {
    background-color: darken(#207895, 10%);
  }
}

.register-btn {
  background-color: #207895;
  color: white;

  &:hover {
    background-color: lighten(#207895, 5%);
  }

  &:active {
    background-color: darken(#207895, 10%);
  }
}

/* Side Menu Styling */
.side-menu {
  position: fixed;
  top: 0;
  left: -100%;
  height: 100%;
  width: 70%;
  max-width: 300px;
  background-color: $primary-revolt-color;
  transition: left 0.3s ease;
  padding: 1rem;
  z-index: 1000;
  overflow-y: auto;
}

.side-menu.open {
  left: 0;
}

.side-menu-item {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 1rem;
  color: white;
  font-size: 1rem;
  background: none;
  border: none;
  text-align: left;
  width: 100%;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  i {
    font-size: 1.25rem;
  }
}

.mobile-search {
  display: flex;
  justify-content: center;
  padding: 1rem;
  background-color: $primary-revolt-color;
}

.mobile-search .search-input {
  width: 100%;
  padding: 0.75rem;
  border-radius: 8px;
  border: none;
}

.profile-dropdown {
  position: relative;
}

.profile-dropdown .dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 0.2rem 0;
  text-align: center;
}

.profile-dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-menu button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background: none;
  border: none;
  width: 100%;
  text-align: center;
  color: $primary-revolt-color;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: lighten($primary-revolt-color, 85%);
  }
}

.mobile-icons {
  display: flex;
  gap: 0.3rem;
  background-color: $primary-revolt-color;
  padding: 0.75rem;
  width: 100%;
  overflow-x: hidden;
}

.header-icon {
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.1);
  }

  &:active {
    transform: scale(0.95);
  }
}

.cart-btn {
  background-color: #207895;
  color: white;
  position: relative;

  &:hover {
    background-color: lighten(#207895, 5%);
  }

  &:active {
    background-color: darken(#207895, 10%);
  }

  .cart-badge {
    position: absolute;
    /* Position it relative to the cart button */
    top: -5px;
    /* Adjust to fine-tune the position */
    right: -5px;
    /* Adjust to fine-tune the position */
    background-color: #e85252;
    /* Bright red for visibility */
    color: white;
    border-radius: 50%;
    font-size: 0.75rem;
    font-weight: bold;
    padding: 0.2rem 0.5rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 1.5rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    /* Add subtle shadow for depth */
  }
}

.cart-mobile-btn {
  position: relative; /* Enable positioning for the badge */
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.1);
  }

  &:active {
    transform: scale(0.95);
  }

  .cart-badge {
    scale: 0.9;
    position: absolute; /* Position it relative to the mobile cart button */
    top: -5px; /* Adjust to fine-tune placement */
    right: -5px; /* Adjust to fine-tune placement */
    background-color: #ff3d00; /* Bright red for visibility */
    color: white;
    border-radius: 50%;
    font-size: 0.75rem;
    font-weight: bold;
    padding: 0.2rem 0.5rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 1.5rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Add subtle shadow for depth */
  }
}

.sales-banner {
  background-color: #2589aa; /* Use your platform's primary color */
  color: white;
  text-align: center;
  padding: 0.5rem;
  font-size: 1.1rem;
  position: sticky;
  top: -10; /* Aligns with the top of the viewport */
  z-index: 999; /* Ensures it appears above the header and other elements */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Adds slight shadow for depth */
  transition: background-color 0.3s ease;

  &:hover {
    background-color: lighten(#2589aa, 5%); /* Lighter shade on hover */
    color: #fefefe;
  }
}

@media (max-width: 768px) {
  .sales-banner {
    font-size: 1rem;
    padding: 0.3rem;
  }
}

</style>
