<template>
  <div class="privacy-policy-container">
    <!-- Header -->
    <div class="header-section">
      <h1>Privatumo Politika</h1>
      <p>
        Sužinokite, kaip mes tvarkome, saugome ir naudojame jūsų asmens duomenis.
      </p>
    </div>

    <!-- Card-Based Content -->
    <div class="cards-container">
      <!-- Card 1 -->
      <div class="card">
        <div class="card-icon">
          <i class="fa fa-database"></i>
        </div>
        <div class="card-content">
          <h3>Informacijos Rinkimas</h3>
          <p>
            Mes renkame tik <strong>būtiniausią informaciją</strong>, kad užtikrintume sklandų jūsų apsipirkimo
            procesą.
          </p>
        </div>
      </div>

      <!-- Card 2 -->
      <div class="card">
        <div class="card-icon">
          <i class="fa fa-shield-alt"></i>
        </div>
        <div class="card-content">
          <h3>Informacijos Naudojimas</h3>
          <p>
            Surinkta informacija naudojama tik <strong>teisės aktuose numatytiems tikslams</strong>.
          </p>
        </div>
      </div>

      <!-- Card 3 -->
      <div class="card">
        <div class="card-icon">
          <i class="fa fa-lock"></i>
        </div>
        <div class="card-content">
          <h3>Informacijos Apsauga</h3>
          <p>
            Jūsų asmeniniai duomenys yra apsaugoti <strong>šiuolaikinėmis saugumo priemonėmis</strong>.
          </p>
        </div>
      </div>

      <!-- Card 4 -->
      <div class="card single-centered">
        <div class="card-icon">
          <i class="fa fa-user-check"></i>
        </div>
        <div class="card-content">
          <h3>Jūsų Teisės</h3>
          <p>
            Jūs turite teisę <strong>susipažinti su savo duomenimis</strong>, juos taisyti ar pašalinti.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "/src/styles/variable";

/* General Container */
.privacy-policy-container {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  border-radius: 10px;

  /* Header Section */
  .header-section {
    text-align: center;
    margin-bottom: 2rem;

    h1 {
      font-size: 2rem;
      color: #2cb6d2;
      font-weight: bold;
    }

    p {
      color: #555;
      font-size: 1.1rem;
    }
  }
  .cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 1.5rem;
  justify-items: center;

  /* Center Single Card on Larger Screens */
  @media (min-width: 768px) {
    .single-centered {
      grid-column: 2 / span 1;
    }
  }

  .card {
    background-color: #fff;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
    }

    .card-icon {
      background-color: #eaf8fc;
      color: #2cb6d2;
      font-size: 2rem;
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      margin-bottom: 1rem;
    }

    h3 {
      font-size: 1.3rem;
      margin-bottom: 0.5rem;
      color: #333;
    }

    p {
      color: #555;
      line-height: 1.6;
      font-size: 0.95rem;
    }
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .cards-container {
    grid-template-columns: 1fr;

    .single-centered {
      grid-column: auto; /* Resets centering for smaller screens */
    }
  }
}
}

/* Responsive Design */
@media (max-width: 768px) {
  .cards-container {
    grid-template-columns: 1fr;

    .single-centered {
      grid-column: auto; /* Resets centering for smaller screens */
    }
  }
}
</style>
