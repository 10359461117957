<template>
  <div class="register-container">
    <div class="register-card">
      <h2 class="register-title">Registruotis</h2>

      <div class="message-container">
        <MessageComponent v-if="message" :message="message" :type="messageType" />
      </div>

      <form @submit.prevent="handleSubmit">
        <div class="form-group">
          <label for="firstName">Vardas</label>
          <input type="text" id="firstName" v-model="firstName" required />
        </div>
        <div class="form-group">
          <label for="lastName">Pavardė</label>
          <input type="text" id="lastName" v-model="lastName" required />
        </div>
        <div class="form-group">
          <label for="email">El. paštas</label>
          <input type="email" id="email" v-model="email" required />
        </div>
        <div class="form-group">
          <label for="password">Slaptažodis</label>
          <input type="password" id="password" v-model="password" required />
        </div>
        <div class="form-group">
          <label for="confirmPassword">Pakartok slaptažodį</label>
          <input type="password" id="confirmPassword" v-model="confirmPassword" required />
        </div>
        <button type="submit" class="register-btn">Registruotis</button>
        <p class="already-registered-text">
          Jau turi paskyrą?
          <a class="login-link" @click="goToLoginPage">Prisijungti</a>
        </p>
      </form>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import MessageComponent from "../message/MessageComponent.vue";
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
export default {
  name: "registerPageComponent",
  components: {
    MessageComponent,
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      message: "",
      messageType: "",
    };
  },

mounted() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
},
  methods: {
    goToLoginPage() {
      this.$router.push({ name: "Login" });
    },

    async handleSubmit() {
      this.clearMessages();

      if (this.password !== this.confirmPassword) {
        this.showMessage("Slaptažodžiai nesutampa!", "error");
        return;
      }

      try {
        await axios.post(`${API_BASE_URL}/user/create`, {
          first_name: this.firstName,
          last_name: this.lastName,
          email: this.email,
          password: this.password,
        });

        this.showMessage("Sėkmingai užsiregistravote!", "success");

        setTimeout(() => {
          this.clearForm();
          this.goToLoginPage();
        }, 1000);
      } catch (error) {
        this.handleError(error);
      }
    },
    showMessage(message, type) {
      this.message = message;
      this.messageType = type;

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },

    clearMessages() {
      this.message = "";
      this.messageType = "";
    },

    clearForm() {
      this.firstName = "";
      this.lastName = "";
      this.email = "";
      this.password = "";
      this.confirmPassword = "";
    },

    handleError(error) {
      if (error.response && error.response.data.errors) {
        error.response.data.errors.forEach((err) => {
          this.showMessage(err, "error");
        });
      } else {
        this.showMessage(
          "Nepavyko užsiregistruoti. Bandykite dar kartą.",
          "error"
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "/src/styles/variable";

.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 1rem;
  background-color: #f4f7fa;
}

.register-card {
  background-color: #fff;
  padding: 2.5rem;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.register-title {
  font-weight: bold;
  margin-bottom: 1.5rem;
  font-size: 1.8rem;
  text-align: left
}

.message-container {
  margin-bottom: 1rem;
  animation: fadeIn 0.5s ease;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.form-group {
  margin-bottom: 1.2rem;
  text-align: left;

  label {
    display: block;
    margin-bottom: 0.4rem;
    color: $foreground-color;
    font-size: 1rem;
  }

  input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid $border-color;
    border-radius: 6px;
    background-color: #f9fafb;
    color: $foreground-color;
    font-size: 0.95rem;

    &:focus {
      outline: none;
      border-color: $ring-color;
      box-shadow: 0 0 0 3px fade-out($ring-color, 0.7);
    }
  }
}

.register-btn {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  font-weight: bold;
  background-color: $primary-revolt-color;
  color: #fff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    background-color: darken($primary-revolt-color, 8%);
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
  }
}

.already-registered-text {
  margin-top: 1rem;
  font-size: 0.9rem;
  color: black;
}

.login-link {
  color: $primary-revolt-color;
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;

  &:hover {
    color: darken($primary-revolt-color, 10%);
    text-decoration: underline;
  }
}
</style>
