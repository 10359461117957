<template>
    <div v-if="show" class="product-sold-page">
      <div class="product-sold-content">
        <div class="icon-container">
          <i class="fas fa-shopping-bag"></i>
        </div>
        <h1 class="sold-title">Deja, šis produktas jau parduotas.</h1>
        <p class="sold-message">{{ message }}</p>
        <div class="action-buttons">
          <button @click="goToHome" class="btn btn-primary">Grįžti į pagrindinį puslapį</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "ProductSoldComponent",
    props: {
      message: {
        type: String,
        default: "Atsiprašome, bet šio produkto nebeturime.",
      },
      show: {
        type: Boolean,
        default: true,
      },
    },
    methods: {
      goToHome() {
        this.$router.push({ name: "Home" });
      },
    },
  };
  </script>
  
  <style scoped>
  .product-sold-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: #f8f9fa;
    padding: 2rem;
  }
  
  .product-sold-content {
    text-align: center;
    background: white;
    padding: 3rem 2rem;
    border-radius: 12px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    width: 100%;
  }
  
  .icon-container {
    font-size: 4rem;
    color: #2cb6d2;
    margin-bottom: 1rem;
  }
  
  .sold-title {
    font-size: 2rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 0.5rem;
  }
  
  .sold-message {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 2rem;
  }
  
  .action-buttons {
    display: flex;
    gap: 1rem;
    justify-content: center;
  }
  
  .action-buttons .btn {
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .btn-primary {
    background-color: #2cb6d2;
    color: white;
    border: none;
  }
  
  .btn-primary:hover {
    background-color: #239cb0;
  }
  
  .btn-secondary {
    background-color: #f0f0f0;
    color: #555;
    border: none;
  }
  
  .btn-secondary:hover {
    background-color: #e2e2e2;
  }
  </style>
  