<template>
  <div class="cart-container">
    <div class="cart-card">
      <h2>Jūsų Krepšelis</h2>
      <div v-if="cartItems.length > 0">
        <div style="max-height: 450px; overflow-y: auto;">

          <div class="dekstop-cart-container">
            <div class="cart-item" v-for="(item, index) in cartItems" :key="index">
              <img :src="item.image" alt="product image" class="cart-item-image" @click="goToProductPage(item.id)" />
              <div class="cart-item-details">
                <h4 class="product-name">{{ item.name }}</h4>
                <div style="display: flex; flex-direction: row; gap: 3rem">
                  <div
                    style="display: flex; flex-direction: column; width: 19rem; align-items: flex-start; justify-self: flex-start">
                    <p>Kaina: <span class="price">{{ item.price }} €</span></p>
                    <p>Būklė: {{ item.condition }}</p>
                  </div>
                  <div>
                    <button style="margin-top: 2rem;" class="remove-btn"
                      @click="deleteCartItem(item.id, item.productId)">
                      <i class="fa fa-times"></i> Pašalinti prekę
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mobile-cart-container">
            <div class="cart-item-mobile" v-for="(item, index) in cartItems" :key="index">
              <div style="display: flex; flex-direction: row; text-align: left !important;">
                <img :src="item.image" alt="product image" class="cart-item-image-mobile"
                  @click="goToProductPage(item.id)" />
                <div class="cart-item-details-mobile">
                  <h4 class="product-name-mobile">{{ item.name }}</h4>
                  <div class="cart-item-info-mobile">
                    <p style="margin-bottom: 3px !important;">Total: <span class="price">{{ item.price }} €</span></p>
                    <p>Būklė: {{ item.condition }}</p>
                  </div>
                </div>
              </div>
              <button class="remove-btn-mobile" @click="deleteCartItem(item.id, item.productId)">
                <i class="fa fa-times"></i> Pašalinti prekę
              </button>
            </div>
          </div>

        </div>
        <br />
        <hr />
        <div style="height: 4rem;">
          <div id="warning" v-if="warningMessage" class="warning-message">
            {{ warningMessage }}
          </div>
        </div>
        <div class="userInfoContainer">
          <div class="contact-info">
            <h3>Kontaktinė Informacija</h3>
            <label>Vardas Pavardė</label>
            <input type="text" v-model="full_name" required placeholder="Įveskite vardą ir pavardę"
              class="input-field" />
            <label>El. paštas:</label>
            <input type="email" v-model="email" required placeholder="Įveskite el. paštą" class="input-field" />
            <label>Telefono numeris:</label>
            <input type="tel" v-model="phoneNumber" required placeholder="Įveskite telefono numerį"
              class="input-field" />
          </div>

          <div class="address-info">
            <h3>Pristatymo Adresas</h3>
            <label>Miestas:</label>
            <input type="text" v-model="city" required placeholder="Miestas" class="input-field" />
            <label>Gatvė ir namo numeris:</label>
            <input type="text" v-model="street" required placeholder="Gatvė ir namo numeris" class="input-field" />
            <label>Pašto kodas:</label>
            <input type="text" v-model="postalCode" required placeholder="Pašto kodas" class="input-field" />
          </div>
        </div>
        <div class="note-container">
          <p>
            <strong>Pastaba:</strong> Šiuo metu pristatymas į namus nėra galimas. Jūsų užsakymas bus pristatytas į
            artimiausią paštomatą,
            remiantis Jūsų pateiktu adresu. Atsiprašome už galimus nepatogumus!
          </p>
        </div>
        <div class="cart-summary">
          <div id="wtpQualitySign">
            <div>
              <img src="https://bank.paysera.com/new/img/quality-sign/trust-badge.png" />
            </div>
          </div>
          <div>
            <p class="summary-text">Prekių suma: <span class="price">{{ totalPrice }} €</span></p>
            <button class="checkout-btn" @click="checkout">Apmokėti</button>
          </div>
        </div>
      </div>
      <div v-else>
        <p class="empty-cart">Jūsų krepšelis tuščias</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import EventBus from "@/eventBus";
import getAuthHeaders from "@/utility/sessionId";
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

export default {
  name: "MyCartComponent",
  data() {
    return {
      cartItems: [],
      full_name: "",
      email: "",
      phoneNumber: "",
      city: "",
      street: "",
      postalCode: "",
      warningMessage: "",
    };
  },
  computed: {
    totalPrice() {
      return this.cartItems.reduce((total, item) => total + item.price, 0).toFixed(2);
    },
  },
  mounted() {
    this.getUserCart();
  },
  methods: {
    async checkout() {
      if (!this.full_name || !this.email || !this.phoneNumber || !this.city || !this.street || !this.postalCode) {
        this.warningMessage = "Prašome užpildyti visus laukus prieš tęsiant.";

        setTimeout(() => {
          const warningElement = document.getElementById("warning");
          if (warningElement) {
            warningElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }, 0);

        setTimeout(() => {
          this.warningMessage = "";
        }, 6000);
        return;
      }

      this.trackBeginCheckout();

      try {
        const token = localStorage.getItem("userToken");
        const response = await axios.post(
          `${API_BASE_URL}/payment/initiate`,
          {
            user_id: token ? this.userId : null,
            cart_items: this.cartItems.map((item) => ({ id: item.id })),
            total_price: this.totalPrice,
            shipping_address: {
              city: this.city,
              street: this.street,
              postal_code: this.postalCode,
            },
            full_name: this.full_name,
            email: this.email,
            phone_number: this.phoneNumber,
          },
          token ? { headers: { Authorization: `Bearer ${token}` } } : {}
        );

        // Redirect user to the payment URL provided by the backend
        window.location.href = response.data.redirect_url;
      } catch (error) {
        console.error("Payment initiation error:", error);
      }
    },
    trackBeginCheckout() {
       /* global gtag */
      if (typeof gtag === "function") {
        gtag("event", "begin_checkout", {
          items: this.cartItems.map((item) => ({
            id: item.id,
            name: item.name,
            category: item.condition || "Nežinoma kategorija",
            price: item.price,
            quantity: 1, // Adjust quantity if you support multiples
          })),
          currency: "EUR",
          value: this.totalPrice,
        });
        console.log("Google Analytics: Checkout initiation tracked.");
      } else {
        console.warn("Google Analytics (gtag) not initialized.");
      }
    },
    goToProductPage(productId) {
      this.$router.push({ name: "Product", params: { id: productId } });
    },
    getUserCart() {
      const headers = getAuthHeaders()

      axios
        .get(`${API_BASE_URL}/get-cart`, { headers })
        .then((response) => {
          if (response.status === 200) {
            this.cartItems = response.data || []; // Assume backend returns a `cartItems` array
          } else {
            this.cartItems = [];
          }
        })
        .catch((error) => {
          console.error("Error fetching cart:", error);
          this.cartItems = [];
        });
    },
    deleteCartItem(productId) {

      const headers = getAuthHeaders()

      axios
        .delete(`${API_BASE_URL}/remove-from-cart/${productId}`, { headers })
        .then((response) => {
          if (response.status === 200) {
            this.cartItems = this.cartItems.filter((item) => item.id !== productId);
            EventBus.emit("cartUpdated");
          }
        })
        .catch((error) => {
          console.error("Error removing item from cart:", error.response?.data || error);
        });
    }

  },
};
</script>

<style scoped>
#wtpQualitySign {
  margin: 0.5rem 0;
}

.userInfoContainer {
  display: flex;
  gap: 3rem;
}

@media (max-width: 768px) {


  .userInfoContainer {
    flex-direction: column;
  }

  .address-info {
    text-align: left !important;
  }

  .contact-info {
    text-align: left !important;
  }

}

.cart-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 2rem;
  min-height: 100%;
  background-color: #f7f9fc;
}

.cart-card {
  background-color: #fff;
  color: #333;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 800px;
}

.cart-title {
  background-color: #2cb6d2;
  color: #fff;
  padding: 1rem;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 1.5rem;
  font-weight: bold;
}

.warning-message {
  background-color: #ffe4e4;
  color: #d9534f;
  padding: 0.8rem;
  border-radius: 8px;
  margin-bottom: 1rem;
  font-weight: bold;
  text-align: center;
  border: 1px solid #f5c6c6;
}

.cart-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  background-color: #fafafa;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.2s ease-in-out;
}

.cart-item:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.cart-item-image {
  width: 180px;
  height: 90px;
  border-radius: 8px;
  object-fit: contain;
  cursor: pointer;
}

.cart-item-details {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 1rem;
  height: 100px;
}

.product-name {
  font-size: 1.1rem;
  font-weight: bold;
  color: #333;
}

.price {
  color: #2cb6d2;
  font-weight: bold;
}

.remove-btn {
  background-color: #e0e0e0;
  /* Softer color for less emphasis */
  color: #666;
  /* Subtle text color */
  border: none;
  padding: 0.3rem 0.5rem;
  /* Reduced padding */
  border-radius: 3px;
  /* Slightly smaller radius */
  cursor: pointer;
  font-size: 0.8rem;
  /* Smaller font size */
  transition: background-color 0.2s ease-in-out;
}

.note-container {
  margin-top: 20px;
  padding: 15px;
  background-color: #f8f9fa;
  /* Light grey background */
  border-left: 5px solid #2cb6d2;
  /* Accent color */
  border-radius: 5px;
  font-size: 14px;
  color: #333;
}

.note-container p {
  margin: 0;
  line-height: 1.6;
  text-align: left;
}

.note-container strong {
  color: #2cb6d2;
  /* Accent color for emphasis */
}

.remove-btn:hover {
  background-color: #cccccc;
  /* Slightly darker on hover */
}

.contact-info,
.address-info {
  margin-top: 1.5rem;
  text-align: left;
}

.contact-info h3,
.address-info h3 {
  color: #333;
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.input-field {
  width: 100%;
  padding: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 0.95rem;
  transition: border-color 0.2s;
}

.input-field:focus {
  border-color: #2cb6d2;
  outline: none;
}

.cart-summary {
  text-align: right;
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
}

.summary-text {
  font-size: 1.1rem;
  margin-bottom: 1rem;
}

.checkout-btn {
  padding: 0.75rem 2rem;
  background-color: #2cb6d2;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.checkout-btn:hover {
  background-color: #2496b2;
}

.empty-cart {
  color: #888;
  text-align: center;
  font-size: 1.2rem;
  margin-top: 2rem;
}

.cart-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 2rem;
  min-height: 100%;
  background-color: #f7f9fc;
}

.cart-card {
  background-color: #fff;
  color: #333;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 800px;
}

.cart-title {
  background-color: #2cb6d2;
  color: #fff;
  padding: 1rem;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 1.5rem;
  font-weight: bold;
}

.warning-message {
  background-color: #ffe4e4;
  color: #d9534f;
  padding: 0.8rem;
  border-radius: 8px;
  margin-bottom: 1rem;
  font-weight: bold;
  text-align: center;
  border: 1px solid #f5c6c6;
}

.cart-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  background-color: #fafafa;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.2s ease-in-out;
}

.cart-item:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.cart-item-details {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 1rem;
  height: 100px;
}

.product-name {
  font-size: 1.1rem;
  font-weight: bold;
  color: #333;
}

.price {
  color: #2cb6d2;
  font-weight: bold;
}

.remove-btn {
  background-color: #e0e0e0;
  color: #666;
  border: none;
  padding: 0.3rem 0.5rem;
  border-radius: 3px;
  cursor: pointer;
  font-size: 0.8rem;
  transition: background-color 0.2s ease-in-out;
}

.remove-btn:hover {
  background-color: #cccccc;
}

.contact-info,
.address-info {
  margin-top: 1.5rem;
  text-align: left;
}

.contact-info h3,
.address-info h3 {
  color: #333;
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.input-field {
  width: 100%;
  padding: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 0.95rem;
  transition: border-color 0.2s;
}

.input-field:focus {
  border-color: #2cb6d2;
  outline: none;
}

.cart-summary {
  text-align: right;
  margin-top: 2rem;
}

.summary-text {
  font-size: 1.1rem;
  margin-bottom: 1rem;
}

.checkout-btn {
  padding: 0.75rem 2rem;
  background-color: #2cb6d2;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.checkout-btn:hover {
  background-color: #2496b2;
}

.empty-cart {
  color: #888;
  text-align: center;
  font-size: 1.2rem;
  margin-top: 2rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .cart-card {
    padding: 1rem;
  }

  .cart-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .cart-item-details {
    margin-left: 0;
    margin-top: 1rem;
    align-items: flex-start;
  }

  .cart-item-image {
    width: 70px;
    height: 70px;
  }

  .contact-info,
  .address-info {
    width: 100%;
    margin-top: 1rem;
  }

  .contact-info,
  .address-info,
  .cart-summary {
    text-align: center;
  }

  .summary-text,
  .checkout-btn {
    width: 100%;
    text-align: center;
  }

  .checkout-btn {
    margin-top: 1rem;
  }
}

@media (max-width: 480px) {
  .cart-item-image {
    width: 60px;
    height: 60px;
  }

  .product-name {
    font-size: 1rem;
  }

  .input-field {
    font-size: 0.9rem;
  }

  .summary-text {
    font-size: 1rem;
  }
}

/* Mobile Cart Container Styles */
.cart-item-mobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  background-color: #fafafa;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.cart-item-image-mobile {
  width: 120px;
  height: 140px;
  margin-right: 1rem;
  border-radius: 8px;
  object-fit: contain;
  cursor: pointer;
}

.cart-item-details-mobile {
  margin-top: 0.5rem;
  width: 100%;
}

.product-name-mobile {
  font-size: 1rem;
  font-weight: bold;
  color: #333;
}

.cart-item-info-mobile {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
}

.price {
  color: #2cb6d2;
  font-weight: bold;
}

.remove-btn-mobile {
  background-color: #e0e0e0;
  color: #666;
  border: none;
  padding: 0.5rem 0.8rem;
  border-radius: 3px;
  font-size: 0.8rem;
  transition: background-color 0.2s ease-in-out;
  margin-top: 1rem;
  cursor: pointer;
  width: 100%;
}

.remove-btn-mobile:hover {
  background-color: #cccccc;
}

/* Show mobile cart container on smaller screens */


@media (min-width: 768px) {
  .mobile-cart-container {
    display: none;
  }

}

@media (max-width: 768px) {
  .dekstop-cart-container {
    display: none;
    /* Hide desktop container on mobile */
  }

  #mobile-cart-container {
    display: flex;
    /* Show mobile container on mobile */
  }
}
</style>
