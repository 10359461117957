<template>
  <div class="favorites-wrapper">
    <h2 class="favorites-title">Patikusios prekės</h2>

    <div v-if="favorites.length === 0" class="empty-favorites">
      <p>Čia rodomos jūsų patikusios prekės. Šiuo metu neturite pridėtų prekių.</p>
      <router-link to="/" class="browse-btn">Naršyti prekes</router-link>
    </div>

    <div v-else class="favorites-grid">
      <div v-for="favorite in favorites" :key="favorite.product_id" class="favorite-card">
        <div class="favorite-image-wrapper" @click="goToProductPage(favorite.product_id)">
          <img :src="favorite.main_image" alt="Product image" class="favorite-image" />
        </div>
        <div class="favorite-info">
          <h3 class="favorite-name" @click="goToProductPage(favorite.product_id)">
            {{ favorite.name }}
          </h3>
          <p class="favorite-price">{{ favorite.price }} €</p>
          <button class="unfavorite-btn" @click="unfavorProduct(favorite.product_id)">
            <i class="fas fa-heart"></i> Pašalinti iš favoritų
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import getAuthHeaders from "@/utility/sessionId";
import axios from "axios";

const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

export default {
  name: "FavoritesTab",
  data() {
    return {
      favorites: [],
    };
  },
  mounted() {
    this.fetchLikedProducts();
  },
  methods: {
    fetchLikedProducts() {
      const token = localStorage.getItem("userToken");
      if (!token) return;

      axios
        .get(`${API_BASE_URL}/product/liked-products`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.favorites = response.data;
        })
        .catch((error) => {
          console.error("Error fetching liked products:", error);
        });
    },
    goToProductPage(productId) {
      this.$router.push({ name: "Product", params: { id: productId } });
    },
    unfavorProduct(productId) {

      const headers = getAuthHeaders();
      axios
        .post(
          `${API_BASE_URL}/product/${productId}/like`,
          {},
          {
            headers
          }
        )
        .then(() => {
          this.favorites = this.favorites.filter(
            (favorite) => favorite.product_id !== productId
          );
        })
        .catch((error) => {
          console.error("Error unfavoring product:", error);
        });
    },
  },
};
</script>

<style scoped>
/* Wrapper */
.favorites-wrapper {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.favorites-title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 1.5rem;
  text-align: center;
}

/* Empty Favorites */
.empty-favorites {
  text-align: center;
  color: #666;
  font-size: 18px;
  margin-top: 2rem;
}

.browse-btn {
  display: inline-block;
  margin-top: 1rem;
  padding: 0.75rem 1.5rem;
  background-color: #2cb6d2;
  color: #fff;
  border-radius: 5px;
  text-decoration: none;
  font-size: 16px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #249ba8;
  }
}

/* Favorites Grid */
.favorites-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
}

/* Favorite Card */
.favorite-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
}

/* Favorite Image */
.favorite-image-wrapper {
  position: relative;
  overflow: hidden;
  cursor: pointer;

  &:hover img {
    transform: scale(1.1);
  }
}

.favorite-image {
  width: 100%;
  height: 200px;
  object-fit: contain;
  transition: transform 0.3s ease;
}

/* Favorite Info */
.favorite-info {
  padding: 1rem;
  text-align: left;
}

.favorite-name {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 0.5rem;
  cursor: pointer;

  &:hover {
    color: #2cb6d2;
  }
}

.favorite-price {
  font-size: 16px;
  color: #666;
  margin-bottom: 1rem;
}

/* Unfavorite Button */
.unfavorite-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  background-color: #d9534f;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  i {
    margin-right: 0.5rem;
  }

  &:hover {
    background-color: #b52e2e;
  }
}
</style>