<template>
    <div v-if="isVisible" class="payment-failed-modal">
      <div class="modal-content">
        <!-- Cross Icon -->
        <div class="cross-icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <circle cx="12" cy="12" r="10"></circle>
            <line x1="15" y1="9" x2="9" y2="15"></line>
            <line x1="9" y1="9" x2="15" y2="15"></line>
          </svg>
        </div>
        <h2 class="modal-title">Mokėjimas nepavyko</h2>
        <p class="modal-message">
          Atsiprašome, bet jūsų mokėjimas nepavyko. Prašome pabandyti dar kartą.
        </p>
        <div class="order-summary">
          <p><strong>Užsakymo numeris:</strong> {{ orderId }}</p>
        </div>
        <button @click="closeModal" class="retry-button">Pabandykite iš naujo</button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "PaymentFailed",
    props: {
      orderId: {
        type: String,
        required: false,
      },
    },
    data() {
      return {
        isVisible: true,
      };
    },
    methods: {
      closeModal() {
        this.isVisible = false;
        // Redirect back to cart or home for retry
        this.$router.push('/cart');
      },
    },
  };
  </script>
  
  <style scoped>
  .payment-failed-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 8px;
    text-align: center;
    max-width: 500px;
    width: 90%;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  
  .cross-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .cross-icon svg {
    width: 48px;
    height: 48px;
    color: #d62828; /* No color styling for simplicity */
  }
  
  .modal-title {
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }
  
  .modal-message {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 1.5rem;
  }
  
  .order-summary {
    font-size: 1rem;
    color: #555;
    margin-bottom: 1.5rem;
  }
  
  .retry-button {
    background-color: #e63946; /* Subtle red for retry button */
    color: #ffffff;
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .retry-button:hover {
    background-color: #d62828; /* Slightly darker red on hover */
  }
  </style>
  