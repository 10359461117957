<template>
  <div class="delivery-terms">
    <!-- Page Header -->
    <div class="page-header">
      <h1 class="page-title">Pristatymo Sąlygos</h1>
      <p class="page-subtitle">
        Aiškios ir skaidrios pristatymo procedūros, kad jūsų užsakymas pasiektų jus greitai ir patikimai.
      </p>
    </div>

    <!-- Steps Section -->
    <div class="steps-container">
      <!-- Step 1: Delivery Time -->
      <div class="step-item">
        <div class="step-number">1</div>
        <div class="step-content">
          <h2>Pristatymo Laikas</h2>
          <p>
            Užsakymai paprastai pristatomi per <strong>4–8 darbo dienas</strong> į jūsų pasirinktą paštomatą. Esant
            trikdžiams, pristatymas gali užtrukti iki 2 savaičių.
          </p>
        </div>
      </div>

      <!-- Step 2: Delivery Methods -->
      <div class="step-item">
        <div class="step-number">2</div>
        <div class="step-content">
          <h2>Pristatymo Būdai</h2>
          <p>
            Bendradarbiaujame su patikimomis kurjerių ir pašto tarnybomis. Visi užsakymai turi <strong>stebėjimo numerį</strong>, 
            kad galėtumėte sekti savo siuntą.
          </p>
        </div>
      </div>

      <!-- Step 3: Delivery Costs -->
      <div class="step-item">
        <div class="step-number">3</div>
        <div class="step-content">
          <h2>Pristatymo Kaina</h2>
          <p>
            Pristatymo kaina priklauso nuo prekės dydžio ir svorio. Tiksli suma bus nurodyta <strong>prieš patvirtinant užsakymą</strong>.
          </p>
        </div>
      </div>

      <!-- Step 4: Delivery Terms -->
      <div class="step-item">
        <div class="step-number">4</div>
        <div class="step-content">
          <h2>Pristatymo Sąlygos</h2>
          <p>
            Pristatymo terminai gali būti ilgesni dėl <strong>šventinių dienų</strong> ar kurjerių trikdžių. Apie visus 
            vėlavimus informuosime jus el. paštu arba telefonu.
          </p>
        </div>
      </div>

      <!-- Step 5: Return Policy -->
      <div class="step-item">
        <div class="step-number">5</div>
        <div class="step-content">
          <h2>Grąžinimo Sąlygos</h2>
          <p>
            Visiems produktams galioja <strong>dviejų savaičių grąžinimo terminas</strong> nuo pristatymo dienos. 
            Garantijos sąlygos priklauso nuo prekės būklės. Susisiekite su mumis dėl išsamesnės informacijos.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PristatymoSalygos",
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped lang="scss">
@import "/src/styles/variable";

/* Main Container */
.delivery-terms {
  padding: 2rem 1rem;
  max-width: 900px;
  margin: 0 auto;

  /* Page Header */
  .page-header {
    text-align: center;
    margin-bottom: 2.5rem;

    .page-title {
      font-size: 2rem;
      font-weight: bold;
      color: #2cb6d2;
    }

    .page-subtitle {
      font-size: 1.1rem;
      color: #555;
      margin-top: 0.5rem;
      line-height: 1.5;
    }
  }

  /* Steps Container */
  .steps-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .step-item {
      display: flex;
      align-items: flex-start;
      gap: 1rem;

      .step-number {
        background-color: #2cb6d2;
        color: #fff;
        font-size: 1.5rem;
        font-weight: bold;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        flex-shrink: 0;
      }

      .step-content {
        background-color: #ffffff;
        padding: 1.2rem;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        width: 100%;

        h2 {
          font-size: 1.3rem;
          margin-bottom: 0.5rem;
          color: #333;
        }

        p {
          font-size: 1rem;
          color: #555;
          line-height: 1.6;

          strong {
            color: #2cb6d2;
          }
        }
      }
    }
  }
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .step-item {
    flex-direction: column;

    .step-number {
      position: relative !important;
      top: 40px !important;
      left: -10px !important;
      font-size: 1.2rem !important;
      padding: 5px !important;
      height: 42px !important;
      width: 42px !important;

    }
  }

  .page-title {
    font-size: 1.6rem;
  }

  .page-subtitle {
    font-size: 1rem;
  }
}
</style>
