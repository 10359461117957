<template>
  <div id="app">
    <HeaderComponent />
    <div style="min-height: 75vh;">
      <router-view />
    </div>
    <FooterComponent />
  </div>
</template>

<script>
import FooterComponent from './components/footer/FooterComponent.vue';
import HeaderComponent from './components/header/HeaderComponent.vue';
// import menuBarComponent from './components/sideBar/menuBarComponent.vue';
import { isTokenExpired } from './utility/jwtUtils';

export default {
  name: 'App',
  components: {
    HeaderComponent,
    FooterComponent
  },
  mounted() {
    this.checkToken();
    setInterval(this.checkToken, 60000);
  },
  methods: {
    checkToken() {
      const token = localStorage.getItem('userToken');
      if(token){
        isTokenExpired(token);
      }
    }
  }
}
</script>

<style>
#app {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
