<template>
  <div :class="columnClass" @click="goToProductPage(product.id)" style="cursor: pointer;">
    <div class="card product-card h-100">
      <div class="product-image-container">
        <img :src="product.mainImage" class="card-img-top product-image" :alt="product.name" />
      </div>
      <div class="card-body product-info">
        <p class="card-price">
          {{ formatPrice(product.price) }}
        </p>
        <h5 class="card-title">{{ product.name }}</h5>
        <button class="col-12 btn-buy" :class="{ 'in-cart': product.product_carted }" @click.stop="addToCart"
          :style="product.product_carted ? { backgroundColor: '#2589aa' } : { backgroundColor: '#2cb6d2' }">
          {{ product.product_carted ? "PRODUKTAS KREPŠELYJE" : "Į KREPŠELĮ" }}
        </button>
      </div>
    </div>
  </div>
  <AddToCartModalComponent :show="showCartModal" :productName="product.name" @close="handleClose"
    @go-to-cart="goToCart" />
</template>

<script>
import axios from "axios";
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
import AddToCartModalComponent from "./productPage/AddToCartModalComponent.vue";
import EventBus from "@/eventBus";
import getAuthHeaders from "@/utility/sessionId";

export default {
  name: "ProductCardComponent",
  components: {
    AddToCartModalComponent,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    DisplayRelatedProduct: {
      type: [String, Number, Boolean, Object, Array],
      default: "",
    },
    RelatedProductsMobileView: {
      type: [Boolean],
      default: false,
    }
  },
  data() {
    return {
      showCartModal: false,
      isCarted: false,
      currentScreenWidth: window.innerWidth,
    };
  },
  computed: {
    columnClass() {
      if (this.RelatedProductsMobileView) {
        return 'col-6';
      }
      if (this.currentScreenWidth > 1400 && this.currentScreenWidth < 2200) {
        if (this.DisplayRelatedProduct) {
          return "col-3";
        }
        return "three-columns";
      } else if (this.currentScreenWidth >= 2200 && this.currentScreenWidth) {
        if (this.DisplayRelatedProduct) {
          return "col-3";
        }
        return 'four-columns';
      } else {
        if (this.DisplayRelatedProduct) {
          return "col-4";
        }
        return 'two-columns';
      }
    },
  },
  mounted() {
    window.addEventListener("resize", this.updateColumnClass);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateColumnClass);
  },
  methods: {
    trackAddToCart() {
      // Check if gtag is defined
      /* global gtag */
      if (typeof gtag === "function") {
        gtag("event", "add_to_cart", {
          items: [
            {
              id: this.product.id,
              name: this.product.name,
              category: this.product.subCategory || "Nežinoma kategorija",
              price: this.product.price,
              quantity: 1,
            },
          ],
          currency: "EUR",
          value: this.product.price,
        });
      } else {
        console.warn("Google Analytics (gtag) not initialized.");
      }
    },
    formatPrice(price) {
      return `${price.toFixed(2)} €`;

    },
    addToCart() {
      const headers = getAuthHeaders();
      console.log("headers:", headers)
      axios
        .post(
          `${API_BASE_URL}/add-to-cart/${this.product.id}`,
          {}, // Empty body
          { headers } // Headers go here
        )
        .then((response) => {
          if (response.status === 200) {
            this.showCartModal = true;
            EventBus.emit("cartUpdated");
            this.trackAddToCart();
          }
        })
        .catch((error) => {
          console.error("Error adding product to cart:", error.response?.data || error);
        });
    },
    handleClose() {
      this.showCartModal = false;
    },
    goToProductPage(productId) {
      console.log("clicked go to product page id:", productId);
      window.location.href = `/product/${productId}`;
    },

    updateColumnClass() {
      this.currentScreenWidth = window.innerWidth;
    },
  },
};
</script>

<style lang="scss">
@import "/src/styles/productCard.scss";
@import "/src/styles/main.scss";

.two-columns {
  width: 50%;
  /* Display two product cards per row */
}

.three-columns {
  width: 33.33%;
  /* Display three product cards per row */
}

.four-columns {
  width: 25%;
}

.in-cart {
  background-color: #207895;
  color: white;
}
</style>
