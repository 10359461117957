<template>
    <div class="profile-section">
      <h2 class="section-title">Mano užsakymai</h2>
      <p class="subtitle">Peržiūrėkite savo užsakymų istoriją ir detales.</p>
      <div v-if="orders.length" class="orders-container">
        <div
          v-for="order in orders"
          :key="order.id"
          class="order-card"
          @click="toggleOrderDetails(order.id)"
          :class="[rowClass(order.payment_status), { expanded: expandedOrder === order.id }]"
        >
          <!-- Order Summary -->
          <div class="order-header">
            <div class="order-info-left">
              <div><strong>ID:</strong> {{ order.id }}</div>
              <div><strong>Data:</strong> {{ formatDate(order.created) }}</div>
            </div>
            <div class="order-info-right">
              <div><strong>Būsena:</strong> {{ translateStatus(order.status) }}</div>
              <div><strong>Apmokėjimo būsena:</strong> {{ translatePaymentStatus(order.payment_status) }}</div>
              <div><strong>Suma:</strong> {{ order.order_total.toFixed(2) }} €</div>
            </div>
          </div>
  
          <!-- Order Details -->
          <div v-if="expandedOrder === order.id" class="order-details">
            <h3>Prekės šiame užsakyme:</h3>
            <div class="order-items">
              <div
                v-for="item in order.items"
                :key="item.order_item_id"
                class="order-item-card"
              >
                <div class="order-item-info">
                  <p class="fixePtag"><strong>Produktas:</strong> {{ item.product_name }}</p>
                  <p class="fixePtag"><strong>Būklė:</strong> {{ item.condition }}</p>
                  <p class="fixePtag"><strong>Kategorija:</strong> {{ item.subcategory }}</p>
                  <p class="fixePtag"><strong>Kaina:</strong> {{ item.price.toFixed(2) }} €</p>
                </div>
                <div class="order-item-image">
                  <img :src="item.main_img" alt="Produkto nuotrauka" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p v-else class="no-orders">Neturite užsakymų.</p>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    name: "OrderTab",
    data() {
      return {
        orders: [],
        expandedOrder: null, // Track the currently expanded order ID
      };
    },
    methods: {
      getAuthHeader() {
        const token = localStorage.getItem("userToken");
        return { Authorization: `Bearer ${token}` };
      },
      async fetchOrders() {
        try {
          const response = await axios.get(
            `${process.env.VUE_APP_API_BASE_URL}/orders`,
            {
              headers: this.getAuthHeader(),
            }
          );
          this.orders = response.data;
        } catch (error) {
          console.error("Klaida gaunant užsakymus:", error);
        }
      },
      formatDate(dateString) {
        if (!dateString) return "Neatnaujinta";
  
        try {
          const date = new Date(dateString);
          return date.toLocaleDateString("lt-LT", {
            year: "numeric",
            month: "long",
            day: "numeric",
          });
        } catch {
          return "Neteisinga data";
        }
      },
      translateStatus(status) {
        const statuses = {
          pending: "Užsakymas gautas",
          completed: "Užbaigta",
          canceled: "Atšaukta",
        };
        return statuses[status] || status;
      },
      translatePaymentStatus(paymentStatus) {
        const statuses = {
          waiting_confirmation: "Laukiama patvirtinimo",
          confirmed: "Patvirtinta",
          completed: "Apmokėta",
          canceled: "Atšaukta",
        };
        return statuses[paymentStatus] || paymentStatus;
      },
      rowClass(paymentStatus) {
        return {
          "row-pending": paymentStatus === "waiting_confirmation",
          "row-completed": paymentStatus === "confirmed",
          "row-canceled": paymentStatus === "canceled",
        };
      },
      toggleOrderDetails(orderId) {
        this.expandedOrder = this.expandedOrder === orderId ? null : orderId;
      },
    },
    mounted() {
      this.fetchOrders();
    },
  };
  </script>
  
  <style scoped>
  /* General Layout */
  .profile-section {
    background: #ffffff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .section-title {
    font-size: 24px;
    color: #333;
    margin-bottom: 0.5rem;
    text-align: center;
  }
  
  .subtitle {
    font-size: 16px;
    color: #777;
    margin-bottom: 1.5rem;
    text-align: center;
  }
  
  /* Orders Container */
  .orders-container {
    margin-top: 1.5rem;
  }
  
  /* Order Card */
  .order-card {
    background: #f9f9f9;
    border-radius: 8px;
    margin-bottom: 10px;
    padding: 15px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .order-card:hover {
    background-color: #f0f0f0;
    transform: scale(1.02);
  }
  
  /* Order Header */
  .order-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 15px;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
  }
  
  .order-info-left,
  .order-info-right {
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: 14px;
    color: #555;
  }
  
  .order-info-left {
    text-align: left;
  }
  
  .order-info-right {
    text-align: right;
  }
  
  /* Order Details */
  .order-details {
    margin-top: 15px;
    padding: 15px;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  }
  
  /* Order Items */
  .order-items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
  
  .order-item-card {
    display: flex;
    flex-direction: row;
    gap: 10px;
    background: #f7f7f7;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    text-align: left;
  }

  
  .order-item-info {
    font-size: 14px;
  }
  
  .order-item-image img {
    width: 100%;
    height: 10rem;
    object-fit: contain;
    border-radius: 4px;
  }

  .fixePtag{
    padding: 0 !important;
    margin: 0 !important;
  }
  
  /* Mobile Adjustments */
  @media (max-width: 768px) {

    .order-item-card {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: #f7f7f7;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    text-align: left;
  }

    .order-header {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .order-info-right {
      text-align: left;
      margin-top: 10px;
    }
  
    .order-items {
      grid-template-columns: 1fr;
    }
  
    .order-item-card {
      flex-direction: column;
    }
  }
  
  .no-orders {
    text-align: center;
    font-size: 16px;
    color: #777;
    margin-top: 1.5rem;
  }
  
  /* Row-Specific Colors */
  .row-pending {
    border-left: 4px solid #ffa726;
  }
  
  .row-completed {
    border-left: 4px solid #66bb6a;
  }
  
  .row-canceled {
    border-left: 4px solid #ef5350;
  }
  </style>
  