<template>
  <div class="sidebar bg-light p-1 rounded shadow-sm">
    <h5 class="sidebar-title mb-3 mt-3">Prekių Katalogas</h5>

    <ul class="list-group">
      <li
        v-for="(category, index) in categories"
        :key="index"
        class="list-group-item list-group-item-action"
        @mouseenter="hoveredCategory = index"
        @mouseleave="hoveredCategory = null"
        style="position: relative"
      >
        <i class="me-2 fas" :class="category.icon"></i>
        <a>
          <router-link
              :to="{
                name: 'categoryProducts',
                params: { categoryId: category.id}
              }"
              class="nav-link"
            >
              {{ category.name }}
            </router-link>
        </a>
        <ul
          v-if="hoveredCategory === index && category.subcategories.length > 0"
          class="subcategory-list"
          style="position: absolute; top: -50%; left: 100%; background-color: white; box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); padding: 10px; border-radius: 4px; z-index: 1000;"
        >
          <li
            v-for="(subcategory, subIndex) in category.subcategories"
            :key="subIndex"
            class="list-group-item subcategory-option"
          >
            <router-link
              :to="{
                name: 'subcategoryProducts',
                params: { categoryId: category.id, subcategoryId: subcategory.id }
              }"
              class="nav-link"
            >
              {{ subcategory.name }}
            </router-link>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from "axios";
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

export default {
  name: "SideBarDesktopComponent",
  data() {
    return {
      hoveredCategory: null,
      categories: [],
    };
  },
  methods: {
    async fetchCategories() {
      try {
        const response = await axios.get(`${API_BASE_URL}/categories`);
        this.categories = response.data;
      } catch (error) {
        console.error("Failed to fetch categories:", error);
      }
    }
  },
  mounted() {
    this.fetchCategories();
  },
};
</script>

<style scoped>
.sidebar {
  position: sticky;
  top: 90px;
  z-index: 10; 
  background-color: #f8f9fa;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}


.sidebar-title {
  text-align: center;
  font-weight: 500;
  color: rgb(33, 37, 41);
}

.list-group-item {
  display: flex;
  align-items: center; 
  height: 60px; 
  padding: 0 15px;
  border: none;
  background-color: transparent;
  transition: background-color 0.3s, color 0.3s;
}

.list-group-item:hover {
  background-color: #e9ecef;
  color: #2cb6d2;
}

.list-group-item a {
  text-decoration: none;
  color: inherit;
  flex-grow: 1;
}

.list-group-item a:hover {
  color: #2cb6d2;
  text-decoration: none;
}

.subcategory-option {
  width: 13rem;
}

.list-group-item i {
  font-size: 24px;
  color: #6c757d;
  margin-right: 10px;
}

.list-group-item a {
  display: flex;
  align-items: center;
  height: 100%;
  text-align: left;
  white-space: normal;
}

.sidebar {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.container-fluid {
  padding-left: 15px;
  padding-right: 15px;
}

.subcategory-list {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.subcategory-list li {
  margin-bottom: 5px;
}

.subcategory-list li a {
  color: #6c757d;
  text-decoration: none;
}

.subcategory-list li a:hover {
  color: #2cb6d2;
}

.list-group-item {
  display: flex;
  align-items: center;
  transition: background-color 0.3s, color 0.3s;
}

.list-group-item:hover {
  background-color: #e9ecef;
  cursor: pointer;
}

.list-group-item a {
  flex-grow: 1;
  color: inherit;
  text-decoration: none;
}

.list-group-item a:hover {
  color: #2cb6d2;
}

.me-2 {
  margin-right: 10px;
}

.fas {
  min-width: 35px;
}
</style>
