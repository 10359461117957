<template>
    <div>
        <div ref="mobileSidebar" class="sidebar-mobile" :class="{ open: isOpen }">
            <div class="sidebar-header">
                <button class="close-btn" @click="toggleSideMenu">
                    <i class="fas fa-times"></i>
                </button>
            </div>
            <div class="user-access-section">
                <div v-if="isLoggedIn" class="profile-section" style="color: #2cb6d2;">
                    <div class="auth-link" @click="goToProfile()">
                        <i class="fas fa-user auth-icon"></i>Profilis
                    </div>
                    <div class="auth-link" @click="logout()" style="color: #e05c5c;">
                        <i class="fas fa-sign-out-alt auth-icon"></i>Atsijungti
                    </div>
                </div>
                <div v-else>
                    <div class="auth-link" @click="goToLogin" style="color: #2cb6d2;">
                        <i class="fas fa-sign-in-alt auth-icon"></i>Prisijungti
                    </div>
                    <div class="auth-link" @click="goToRegister" style="color: #2589aa;">
                        <i class="fas fa-user-plus auth-icon"></i>Registruotis
                    </div>
                </div>
            </div>
            <h5 style="font-weight: bold; padding: 1rem 0rem; text-align: left;">Prekių Katalogas</h5>
            <ul class="list-group">
                <li v-for="(category, index) in categories" :key="index" class="list-group-item">
                    <!-- Category Name with Toggle Subcategory Icon -->
                    <div @click="toggleSubcategories(index)" class="category-item">
                        <i class="me-2 fas" :class="category.icon"></i>
                        <span>{{ category.name }}</span>
                    </div>

                    <!-- Subcategories listed under the category -->
                    <ul v-if="openCategoryIndex === index && category.subcategories.length > 0"
                        class="subcategory-list">
                        <li v-for="(subcategory, subIndex) in category.subcategories" :key="subIndex"
                            class="subcategory-option">
                            <span @click="toggleSideMenu">
                                <router-link :to="{
                                    name: 'subcategoryProducts',
                                    params: { categoryId: category.id, subcategoryId: subcategory.id }
                                }" class="nav-link">
                                    {{ subcategory.name }}
                                </router-link>
                            </span>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>

        <div v-if="isOpen" class="sidebar-overlay" @click="toggleSideMenu"></div>
    </div>
</template>

<script>
import axios from "axios";
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
import EventBus from '@/eventBus';

export default {
    name: "SideBarMobileComponent",
    data() {
        return {
            categories: [],
            isOpen: false,
            openCategoryIndex: null,
            isLoggedIn: false, // Tracks login status
        };
    },
    methods: {
        async fetchCategories() {
            try {
                const response = await axios.get(`${API_BASE_URL}/categories`);
                this.categories = response.data;
            } catch (error) {
                console.error("Failed to fetch categories:", error);
            }
        },
        toggleSideMenu() {
            this.isOpen = !this.isOpen;
            this.openCategoryIndex = null; // Reset open category when menu toggles
        },
        toggleSubcategories(index) {
            this.openCategoryIndex = this.openCategoryIndex === index ? null : index;
        },
        goToLogin() {
            this.$router.push({ name: "Login" });
            this.toggleSideMenu(); // Close sidebar after navigating
        },
        goToRegister() {
            this.$router.push({ name: "Register" });
            this.toggleSideMenu();
        },
        goToProfile() {
            this.$router.push({ name: "userProfile" });
            this.toggleSideMenu();
        },
        checkLoginStatus() {
            const token = localStorage.getItem("userToken");
            this.isLoggedIn = !!token; // Update login status based on token presence
        },
        logout() {
            localStorage.removeItem("userToken");
            this.isLoggedIn = false;
            this.$router.push({ name: "Login" });            
            EventBus.emit("cartUpdated");
        },
        handleLoginSuccess(){
            console.log("handling login success in the mobile sidebar")
            this.isLoggedIn = true;
        }
    },
    mounted() {
        this.fetchCategories();
        this.checkLoginStatus();
        
        EventBus.on('loginSuccess', this.handleLoginSuccess);
    },
};
</script>

<style scoped>
/* Sidebar Mobile Sliding Menu */
.sidebar-mobile {
    position: fixed;
    top: 0;
    left: -100%;
    height: 100%;
    width: 80%;
    max-width: 300px;
    background-color: #f8f9fa;
    padding: 1rem;
    box-shadow: 2px 0 8px rgba(0, 0, 0, 0.2);
    transition: left 0.3s ease;
    z-index: 1500;
    overflow-y: auto;
}

.sidebar-mobile.open {
    left: 0;
    overflow-y: auto;
}

.user-access-section {
    border-bottom: 1px solid #ddd;
    margin-bottom: 1rem;
}

.profile-section {
    display: flex;
    flex-direction: column;
}

.user-icon {
    font-size: 1.1rem;
    cursor: pointer;
    color: #6c757d;
    transition: color 0.3s;
}

.user-icon:hover {
    color: #2cb6d2;
}

.auth-buttons {
    display: flex;
    gap: 1rem;
}

.auth-button {
    background: none;
    border: none;
    font-size: 1rem;
    color: #6c757d;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.auth-button:hover {
    color: #2cb6d2;
}

.register-button {
    color: #2cb6d2;
}

.sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sidebar-header h5 {
    font-weight: 500;
    color: rgb(33, 37, 41);
}

.close-btn {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #6c757d;
    margin-left: 10px;
}

/* List Group */
.list-group {
    padding: 0;
    margin: 0;
}

.list-group-item {
    border: none;
    padding: 0;
    cursor: pointer;
}

/* Category Item */
.category-item {
    display: flex;
    text-align: left;
    justify-content: left;
    padding: 20px;
    background-color: transparent;
    transition: background-color 0.3s, color 0.3s;
}

.category-item:hover {
    background-color: #e9ecef;
    color: #2cb6d2;
}

.category-item i {
    font-size: 24px;
    color: #6c757d;
    margin-right: 10px;
}

/* Subcategory List */
.subcategory-list {
    padding-left: 30px;
    margin-top: 5px;
    list-style: none;
}

.subcategory-option {
    padding: 20px 0;
}

.subcategory-option .nav-link {
    color: #6c757d;
    text-decoration: none;
    font-size: 0.9rem;
    text-align: left;
}

.subcategory-option .nav-link:hover {
    color: #2cb6d2;
}

/* Overlay Background */
.sidebar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.auth-link {
    text-align: left;
    font-size: 19px;
    margin: 10px 10px 10px 0;
    display: flex;
    align-items: center;
}

.auth-icon {
    width: 20px;
    font-size: 19px;
    margin-right: 10px;
}
</style>