<template>
  <div class="col-md-8 offset-md-2 col-large-10 offset-large-2 col-vlarge-5 offset-vlarge-3">
    <div class="main-container row-md mb-5" style="margin: 0 !important;">
      <div class="col-sm-5 mt-3 offset-md-1 d-sm-block d-none d-md-block col-md-3 col-large-2 col-vlarge-3">
        <SideBarDesktopComponent />
      </div>
      <div class="col-md-8 mt-3 col-large-5 col-vlarge-8" v-if="productList && productList.length > 0">
        <div class="row g-0" style="width: 95%; margin: auto; margin-bottom: 3rem">
          <ProductCardComponent :DisplayRelatedProduct="false" v-for="product in productList" :key="product.id"
            :product="product" />
        </div>
      </div>

      <div v-else-if="productList && productList.length === 0" class="loading-screen" style="
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    margin-top: 5rem;
  ">
        <p style="margin-top: 20px; padding: 2rem;">
          Produktų pagal jūsų paiešką nerasta
        </p>
      </div>

      <div v-else class="loading-screen" style="
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    margin-top: 5rem;
  ">
        <div class="spinner"></div>
        <p style="margin-top: 20px; padding: 2rem;">
          Produktų duomenys kraunami, prašome palaukti...
        </p>
      </div>
      
    </div>
    <div class="pagination-container"
          style="display: flex; justify-content: center; align-items: center; margin-top: 2rem; margin-bottom: 2rem">
          <button class="btn btn-primary" :disabled="currentPage === 1" @click="changePage(currentPage - 1)">
            Ankstesnis
          </button>
          <span style="margin: 0 1rem;">Puslapis {{ currentPage }} iš {{ totalPages }}</span>
          <button class="btn btn-primary" :disabled="currentPage === totalPages" @click="changePage(currentPage + 1)">
            Kitas
          </button>
        </div>
  </div>
</template>

<script>
import ProductCardComponent from "./ProductCardComponent.vue";
import SideBarDesktopComponent from "./SideBarDesktopComponent.vue";
import axios from "axios";
import EventBus from "@/eventBus";
import getAuthHeaders from "@/utility/sessionId";
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

export default {
  name: "HomePageComponent",
  components: {
    ProductCardComponent,
    SideBarDesktopComponent,
  },
  data() {
    return {
      productViewingType: "default", // Default viewing type
      productList: null,
      currentPage: 1, // Current page
      totalPages: 0, // Total number of pages
      pageSize: 12, // Number of items per page
    };
  },
  watch: {
    "$route.params": {
      handler(newParams) {
        this.fetchProducts(newParams.categoryId, newParams.subcategoryId);
      },
      immediate: true,
    },
  },
  methods: {
    async fetchProducts(categoryId, subcategoryId) {
      try {
        const headers = getAuthHeaders();

        let response;
        const params = {
          limit: this.pageSize,
          page: this.currentPage,
          productViewingType: this.productViewingType
        };

        if (categoryId && subcategoryId) {
          if(params.productViewingType != "viewingSubcategory"){
            params.currentPage = 1;
          }
          response = await axios.get(
            `${API_BASE_URL}/category/${categoryId}/subcategory/${subcategoryId}/products`,
            { headers, params }
          );
          params.productViewingType = "viewingSubcategory"
        } else if (categoryId && !subcategoryId) {
          if(params.productViewingType != "viewingCategory"){
            params.currentPage = 1;
          }
          response = await axios.get(
            `${API_BASE_URL}/category/${categoryId}/products`,
            { headers, params }
          );
          params.productViewingType = "viewingCategory"
        } else {
          response = await axios.get(
            `${API_BASE_URL}/products-page`,
            { headers, params }
          );
          params.productViewingType = "default"
        }
        this.productList = response.data.products;
        this.totalPages = response.data.totalPages || Math.ceil(response.data.total / this.pageSize);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    }
    ,
    changePage(newPage) {
      if (newPage > 0 && newPage <= this.totalPages) {
        this.currentPage = newPage;
        this.fetchProducts(this.$route.params.categoryId, this.$route.params.subcategoryId);
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    },
  },
  mounted() {
    EventBus.on("searchResults", (products) => {
      if (!products) {
        this.fetchProducts();
        return;
      }
      this.productList = products;
    });
  },
  beforeUnmount() {
    EventBus.off("searchResults");
  },
};
</script>

<style scoped>
@media (min-width: 768px) {
  .row-md {
    display: flex;
    flex-wrap: wrap;
    margin-right: -0.75rem;
    margin-left: -0.75rem;
  }
}
</style>
