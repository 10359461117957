<template>
  <div class="conditions-page-container">
    <!-- Page Header -->
    <div class="page-header mb-4">
      <h1 class="page-title">Prekių būklių aprašymai</h1>
      <p class="page-subtitle">
        Sužinokite, ką reiškia skirtingos prekių būklės, kad galėtumėte lengvai išsirinkti tai, kas jums labiausiai tinka.
      </p>
    </div>

    <!-- Condition Cards -->
    <div class="conditions-grid">
      <div v-for="condition in conditions" :key="condition.id" class="condition-card">
        <div class="icon-container">
          <i :class="condition.icon" class="condition-icon"></i>
        </div>
        <h2 class="condition-title">{{ condition.name }}</h2>
        <p class="condition-description">{{ condition.description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

export default {
  name: "ProductConditionDescriptions",
  data() {
    return {
      conditions: [
      ],
    };
  },
  methods: {
    async fetchAllConditions() {
      try {
        const response = await axios.get(`${API_BASE_URL}/conditions`);
        this.conditions = response.data.map((condition, index) => ({
          ...condition,
          icon: this.getIconForCondition(index) // Dynamically assign icons
        }));
      } catch (error) {
        console.error("Failed to fetch conditions:", error);
      }
    },
    getIconForCondition(index) {
      // Assign icons dynamically based on index (fallback icons)
      const icons = ["fa fa-star", "fa fa-check-circle", "fa fa-undo", "fa fa-exclamation-circle"];
      return icons[index % icons.length];
    }
  },
  mounted() {
    this.fetchAllConditions();
    window.scrollTo(0, 0); // Scrolls to the top when the page loads
  }
};
</script>

<style scoped lang="scss">
@import "/src/styles/variable";

/* Page Container */
.conditions-page-container {
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  /* Header Section */
  .page-header {
    text-align: center;
    margin-bottom: 2rem;

    .page-title {
      font-size: 2rem;
      font-weight: bold;
      color: #2cb6d2;
      margin-bottom: 0.5rem;
    }

    .page-subtitle {
      font-size: 1.1rem;
      color: #555;
      max-width: 600px;
      margin: 0 auto;
      line-height: 1.6;
    }
  }
}

/* Grid Layout for Cards */
.conditions-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  width: 100%;
  max-width: 1200px;

  .condition-card {
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    text-align: center;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    }

    .icon-container {
      background-color: #eaf8fc;
      color: #2cb6d2;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto 1rem;

      .condition-icon {
        font-size: 1.8rem;
      }
    }

    .condition-title {
      font-size: 1.3rem;
      font-weight: bold;
      margin-bottom: 0.5rem;
      color: #333;
    }

    .condition-description {
      font-size: 1rem;
      color: #555;
      line-height: 1.5;
    }
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .page-title {
    font-size: 1.6rem;
  }

  .page-subtitle {
    font-size: 1rem;
  }

  .condition-title {
    font-size: 1.2rem;
  }

  .condition-description {
    font-size: 0.95rem;
  }
}
</style>
