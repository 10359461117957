<template>
  <div class="about-us-container">
    <!-- Page Header -->
    <div class="page-header">
      <h1 class="page-title">Apie mus</h1>
      <p class="page-subtitle">
        Sužinokite, kaip <strong>Revolt.lt</strong> kuria vertę, siūlydami naujas, naudotas ir atnaujintas prekes už
        itin prieinamą kainą.
      </p>
    </div>

    <!-- Timeline Section -->
    <div class="timeline">
      <!-- Mission -->
      <div class="timeline-item">
        <div class="timeline-icon">
          <i class="fa fa-bullseye"></i>
        </div>
        <div class="timeline-content">
          <h2>Mūsų Misija</h2>
          <p>
            Revolt.lt siekia užtikrinti prekių kokybę, siūlydami skirtingos būklės elektronikos prietaisus. Kiekvienas
            produktas kruopščiai įvertinamas, kad atitiktų jūsų poreikius.
          </p>
        </div>
      </div>

      <!-- Pricing -->
      <div class="timeline-item">
        <div class="timeline-icon">
          <i class="fa fa-tag"></i>
        </div>
        <div class="timeline-content">
          <h2>Kainodara</h2>
          <p>
            Kainos priklauso nuo prekės būklės ir nustatomos atinkamai, remiantis jos pradinės vertės įvertinimu. Tai
            padeda
            užtikrinti aiškų ir itin patrauklų kainos bei kokybės santykį.
          </p>

        </div>
      </div>

      <!-- Quality Assurance -->
      <div class="timeline-item">
        <div class="timeline-icon">
          <i class="fa fa-check-circle"></i>
        </div>
        <div class="timeline-content">
          <h2>Kokybės Užtikrinimas</h2>
          <p>
  Atsižvelgiant į skirtingas prekių būklės kategorijas, siūlome skirtingas garantijos sąlygas. Visoms prekėms galioja 
  dviejų savaičių grąžinimo terminas nuo pristatymo dienos.
</p>

        </div>
      </div>

      <!-- Delivery -->
      <div class="timeline-item">
        <div class="timeline-icon">
          <i class="fa fa-truck"></i>
        </div>
        <div class="timeline-content">
          <h2>Pristatymas</h2>
          <p>
            Užtikriname patogų ir efektyvų pristatymą į paštomatus visoje Lietuvoje. Siekiame, kad jūsų užsakymas
            pasiektų jus greitai.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutUsComponent",
  mounted() {
    window.scrollTo(0, 0); // Scrolls to the top when the page loads
  },
};
</script>

<style scoped lang="scss">
@import "/src/styles/variable";

.about-us-container {
  padding: 2rem 1rem;
  max-width: 900px;
  margin: 0 auto;

  /* Page Header */
  .page-header {
    text-align: center;
    margin-bottom: 2.5rem;

    .page-title {
      font-size: 2rem;
      font-weight: bold;
      color: #2cb6d2;
    }

    .page-subtitle {
      font-size: 1.1rem;
      color: #555;
      line-height: 1.5;
      max-width: 700px;
      margin: 0 auto;
    }
  }

  /* Timeline */
  .timeline {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 20px;
      width: 4px;
      height: 100%;
      background-color: #e0e0e0;
      border-radius: 2px;
    }

    .timeline-item {
      display: flex;
      align-items: flex-start;
      margin-bottom: 2rem;

      &:last-child {
        margin-bottom: 0;
      }

      .timeline-icon {
        background-color: #2cb6d2;
        color: #fff;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 1rem;
        position: relative;
        z-index: 1;
        margin-top: 3rem;

        i {
          font-size: 1.2rem;
        }
      }

      .timeline-content {
        background-color: #ffffff;
        padding: 1.2rem;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        width: 100%;

        h2 {
          font-size: 1.3rem;
          margin-bottom: 0.5rem;
          color: #333;
        }

        p {
          font-size: 1rem;
          color: #555;
          line-height: 1.6;
        }
      }
    }
  }
}


/* Responsive Adjustments */
@media (max-width: 768px) {
  .timeline::before {
    left: 10px;
  }

  .timeline-icon {
    margin-right: 0.5rem !important;
    width: 46px !important;
    height: 42px !important;

    i {
      font-size: 1rem !important;
    }
  }

  .timeline-content {
    padding: 1rem !important;

    h2 {
      font-size: 1.2rem !important;
    }

    p {
      font-size: 0.95rem !important;
    }
  }
}
</style>
