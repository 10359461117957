<template>
    <div class="payment-status-container">
      <div v-if="paymentConfirmed">
        <PaymentSuccess :orderId="orderId" :totalAmount="totalAmount" />
      </div>
      <div v-else class="loading-container">
        <div class="spinner"></div>
        <p>Patvirtiname jūsų mokėjimą. Prašome palaukti...</p>
      </div>
    </div>
  </template>
  
  <script>
  import PaymentSuccess from './PaymentSuccess.vue';
  import axios from 'axios';
  
  export default {
    name: "PaymentSuccessBefore",
    components: {
      PaymentSuccess,
    },
    data() {
      return {
        paymentConfirmed: false,
        orderId: null,
        totalAmount: null,
      };
    },
    async created() {
      try {
        const orderId = this.$route.query.order_id;
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/payment/success`, {
          params: { order_id: orderId },
        });
  
        if (response.data.message === 'Payment successful') {
          this.paymentConfirmed = true;
          this.orderId = response.data.orderId;
          this.totalAmount = response.data.totalAmount;
        }
      } catch (error) {
        console.error('Error confirming payment:', error);
        this.paymentConfirmed = false;
      }
    },
  };
  </script>
  
  <style scoped>
  .payment-status-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 60vh;
    text-align: center;
  }
  
  .loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .spinner {
    width: 40px;
    height: 40px;
    border: 4px solid rgba(44, 182, 210, 0.2);
    border-top-color: #2cb6d2;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  p {
    font-size: 1.2rem;
    color: #333;
    margin-top: 1rem;
  }
  </style>
  