<template>
    <div v-if="show" class="modal-overlay" @click.self="closeModal">
        <div class="modal-content">
            <i class="fas fa-check-circle success-icon"></i>
            <h2 class="modal-title">Produktas sėkmingai pridėtas į krepšelį!</h2>
            <p class="product-name">{{ productName }}</p>
            <div class="modal-buttons">
                <button @click="closeModal" class="modal-button">Tęsti apsipirkimą</button>
                <button @click="goToCart" class="modal-button" style="background-color: #207895 !important;">Eiti į
                    krepšelį</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        show: {
            type: Boolean,
            default: false
        },
        productName: {
            type: String,
            required: true
        }
    },
    methods: {
        closeModal() {
            this.$emit("close");
        },
        goToCart() {
            this.$router.push({ name: "Cart" });
        }
    }
};
</script>

<style scoped>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: white;
    padding: 20px; /* Reduced padding for mobile */
    border-radius: 12px;
    text-align: center;
    width: 90%; /* Use percentage for mobile */
    max-width: 620px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
}

.success-icon {
    color: #77dd77;
    font-size: 40px; /* Slightly smaller for mobile */
    margin-bottom: 15px;
}

.modal-title {
    font-size: 1.3rem; /* Smaller font for mobile */
    font-weight: bold;
    margin-bottom: 10px;
}

.product-name {
    font-size: 1rem;
    font-weight: 500;
    color: #333;
    margin-bottom: 20px;
}

.modal-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Stacks buttons vertically on mobile */
}

.modal-button {
    background-color: #2baec9;
    border: none;
    color: white;
    padding: 12px;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%; /* Full-width buttons for mobile */
}

.modal-button:hover {
    background-color: #2399b1;
}

/* Media query for mobile adjustments */
@media (min-width: 600px) {
    .modal-content {
        padding: 40px 50px;
    }

    .modal-buttons {
        flex-direction: row; /* Horizontal layout on larger screens */
        gap: 20px;
    }

    .success-icon {
        font-size: 50px;
    }

    .modal-title {
        font-size: 1.5rem;
    }

    .product-name {
        font-size: 1.1rem;
        margin-bottom: 30px;
    }
}
</style>
