import { createRouter, createWebHistory } from "vue-router";
import HomePageComponent from "@/components/homePage/HomePageComponent.vue";
import productPageComponent from "@/components/homePage/productPage/productPageComponent.vue";
import LoginPageComponent from "@/components/user/loginPageComponent.vue";
import RegisterPageComponent from "@/components/user/registerPageComponent.vue";
import MyCartComponent from "@/components/user/MyCartComponent.vue";
import ProductConditionDescriptionsElement from "@/components/footer/ProductConditionDescriptionsElement.vue";
import UserProfileComponent from "@/components/user/UserProfileComponent.vue";
import PrivacyPolicyComponent from "@/components/footer/PrivacyPolicyComponent.vue";
import AboutUsComponent from "@/components/footer/AboutUsComponent.vue";
import DeliveryTerms from "@/components/footer/DeliveryTerms.vue";
import ReturnPolicy from "@/components/footer/ReturnPolicy.vue";
import PurchasePolicy from "@/components/footer/PurchasePolicy.vue";
import ContactUs from "@/components/footer/ContactUs.vue";
import PaymentSuccessBefore from "@/components/payment/PaymentSuccessBefore.vue";
import PaymentFailedBefore from "@/components/payment/PaymentFailedBefore.vue";

// New route added for category and subcategory products
const routes = [
  {
    path: "/",
    name: "Home",
    component: HomePageComponent,
  },
  {
    path: "/product/:id",
    name: "Product",
    component: productPageComponent,
    props: true,
  },
  {
    path: "/login",
    name: "Login",
    component: LoginPageComponent,
    meta: { requiresGuest: true, title: "Revolt.lt | Prisijungti" },
  },
  {
    path: "/userProfile/:selectedTab?",
    name: "userProfile",
    component: UserProfileComponent,
    meta: { requiresAuth: true, title: "Revolt.lt | Mano paskyra" },
    beforeEnter: (to, from, next) => {
      if (!to.params.selectedTab) {
        next({ name: "userProfile", params: { selectedTab: "profile" } });
      } else {
        next();
      }
    },
  },
  {
    path: "/register",
    name: "Register",
    component: RegisterPageComponent,
    meta: {
      requiresGuest: true, // Only for guests
      title: "Revolt.lt | Registruotis", // Page title
    },
  },
  {
    path: "/cart",
    name: "Cart",
    component: MyCartComponent,
    meta: { title: "Revolt.lt | Krepšelis" },
  },
  {
    path: "/product-conditions",
    name: "ProductConditionDescriptions",
    component: ProductConditionDescriptionsElement,
    meta: { title: "Revolt.lt | Prekių buklės" },
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicyComponent,
    meta: { title: "Revolt.lt | Privatumo politika" },
  },
  {
    path: "/deliver-terms",
    name: "DeliveryTerms",
    component: DeliveryTerms,
    meta: { title: "Revolt.lt | Pristatymo sąlygos" },
  },
  {
    path: "/about-us",
    name: "AboutUs",
    component: AboutUsComponent,
    meta: { title: "Revolt.lt | Apie mus" },
  },
  {
    path: "/return-policy",
    name: "ReturnPolicy",
    component: ReturnPolicy,
    meta: { title: "Revolt.lt | Gražinimo sąlygos" },
  },
  {
    path: "/purchase-policy",
    name: "PurchasePolicy",
    component: PurchasePolicy,
    meta: { title: "Revolt.lt | Pirkimo ir pardavimo taisyklės" },
  },
  {
    path: "/contact-us",
    name: "ContactUs",
    component: ContactUs,
    meta: { title: "Revolt.lt | Kontaktai/rekvizitai" },
  },
  {
    path: "/category/:categoryId}/products",
    name: "categoryProducts",
    component: HomePageComponent, // This will use HomePageComponent to load the products
    props: true, // Pass route params as props to the component
  },
  {
    path: "/category/:categoryId/subcategory/:subcategoryId",
    name: "subcategoryProducts",
    component: HomePageComponent, // This will use HomePageComponent to load the products
    props: true, // Pass route params as props to the component
  },
  {
    path: "/payment/success",
    name: "PaymentSuccess",
    component: PaymentSuccessBefore,
    props: (route) => ({ orderId: route.query.order_id }),
    meta: { title: "Revolt.lt | Sėkmingai apmokėta" },
  },
  {
    path: "/payment/failure",
    name: "PaymentFailure",
    component: PaymentFailedBefore,
    props: (route) => ({ orderId: route.query.order_id }),
    meta: { title: "Revolt.lt | Apmokėti nepavyko" },
  },

  {
    path: "/:pathMatch(.*)*",
    redirect: { name: "Home" },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Navigation Guard for Route Protection
router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem("userToken"); // Check if user is authenticated

  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !isAuthenticated
  ) {
    next({ name: "Login" });
  }
  else if (
    to.matched.some((record) => record.meta.requiresGuest) &&
    isAuthenticated
  ) {
    next({ name: "userProfile" }); // Redirect to the user profile
  } else {
    next(); // Proceed to the route
  }
});

router.afterEach((to) => {

  if (to.meta && to.meta.title) {
    document.title = to.meta.title;
  }
  
  if (window.gtag) {
    window.gtag("config", "G-VFMYPBNVQP", {
      page_path: to.fullPath,
    });
  }
});


export default router;
