<template>
  <div class="return-policy-container">
    <!-- Header Section -->
    <div class="header-section">
      <h1>Grąžinimo Sąlygos</h1>
      <p>Sužinokite, kaip veikia grąžinimo procesas mūsų platformoje.</p>
    </div>

    <!-- Timeline Layout -->
    <div class="timeline">
      <!-- Step 1: Grąžinimo Laikotarpis -->
      <div class="timeline-item highlight">
        <div class="timeline-icon">
          <i class="fa fa-clock"></i>
        </div>
        <div class="timeline-content">
          <h3>Grąžinimo Laikotarpis</h3>
          <p>
            Visoms prekėms taikomas <strong>dviejų savaičių grąžinimo laikotarpis</strong>. Naudotų prekių grąžinimo sąlygos gali skirtis
            priklausomai nuo būklės.
          </p>
        </div>
      </div>

      <!-- Step 2: Grąžinimo Sąlygos -->
      <div class="timeline-item">
        <div class="timeline-icon">
          <i class="fa fa-check-circle"></i>
        </div>
        <div class="timeline-content">
          <h3>Grąžinimo Sąlygos</h3>
          <p>
            Prekė turi būti grąžinta su <strong>visais priedais</strong> ir, jei įmanoma, originalioje pakuotėje. Sugadintos prekės
            <strong>nepriimamos</strong>.
          </p>
        </div>
      </div>

      <!-- Step 3: Grąžinimo Procesas -->
      <div class="timeline-item">
        <div class="timeline-icon">
          <i class="fa fa-exchange-alt"></i>
        </div>
        <div class="timeline-content">
          <h3>Grąžinimo Procesas</h3>
          <p>
            Susisiekite su mūsų klientų aptarnavimo komanda el. paštu arba telefonu. Pateiksime jums grąžinimo
            instrukcijas ir reikalingą informaciją.
          </p>
        </div>
      </div>

      <!-- Step 4: Grąžinimo Išlaidos -->
      <div class="timeline-item">
        <div class="timeline-icon">
          <i class="fa fa-truck"></i>
        </div>
        <div class="timeline-content">
          <h3>Grąžinimo Išlaidos</h3>
          <p>
            Grąžinimo išlaidas padengia pirkėjas, išskyrus atvejus, kai prekė turi gamyklinį defektą arba neatitinka
            aprašymo.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReturnPolicy",
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped lang="scss">
@import "/src/styles/variable";

/* Main Container */
.return-policy-container {
  padding: 2rem 1.5rem;
  max-width: 900px;
  margin: 0 auto;

  /* Header Section */
  .header-section {
    text-align: center;
    margin-bottom: 2rem;

    h1 {
      font-size: 2rem;
      color: #2cb6d2;
      font-weight: bold;
    }

    p {
      color: #555;
      font-size: 1.1rem;
    }
  }

  /* Timeline Layout */
  .timeline {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: 50%;
      top: 0;
      width: 4px;
      height: 100%;
      background-color: #e0e0e0;
      transform: translateX(-50%);
    }

    .timeline-item {
      display: flex;
      align-items: flex-start;
      margin-bottom: 2rem;
      position: relative;

      &:nth-child(even) {
        flex-direction: row-reverse;

        .timeline-content {
          text-align: right;
        }
      }

      /* Timeline Icon */
      .timeline-icon {
        background-color: #2cb6d2;
        color: #fff;
        font-size: 1.5rem;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
        position: relative;
        z-index: 10;
        margin: 0 1rem;
      }


      @media (max-width: 768px) {
        .timeline-icon {
        top: 30px;
        left: -27px;
        }

        .timeline-content {

          text-align: center !important;
        }

      }


      /* Timeline Content */
      .timeline-content {
        background-color: #fff;
        padding: 1.5rem;
        border-radius: 8px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        max-width: 400px;

        h3 {
          font-size: 1.3rem;
          color: #2cb6d2;
          margin-bottom: 0.5rem;
        }

        p {
          color: #555;
          line-height: 1.6;
        }
      }

      /* Highlighted Step */
      &.highlight .timeline-content {
        border-left: 4px solid #2cb6d2;
        background-color: #eaf8fc;
      }
    }
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .timeline::before {
    left: 2rem;
    transform: none;
  }

  .timeline-item {
    flex-direction: column !important;

    .timeline-icon {
      margin: 0 auto 1rem;
    }

    .timeline-content {
      text-align: center;
      margin: 0 auto;
    }
  }
}
</style>
