<template>
  <div class="profile-wrapper">
    <div class="profile-header">
      <h1>Mano Profilis</h1>
      <p class="subtitle">
        Valdykite savo asmeninę informaciją, užsakymus ir favoritus
      </p>
    </div>
    <div class="profile-tabs">
      <button :class="{ active: activeTab === 'profile' }" @click="goToProfile()">
        Profilis
      </button>
      <button :class="{ active: activeTab === 'orders' }" @click="goToOrders()">
        Mano Užsakymai
      </button>
      <button :class="{ active: activeTab === 'favorites' }" @click="goToFavorites()">
        Patikusios prekės
      </button>
    </div>
    <div class="tab-content">
      <div v-if="activeTab === 'profile'">
        <div class="profile-section">
          <div class="section-header">
            <h2 class="title">Vartotojo duomenys</h2>
            <button @click="editUserProfile()" class="edit-btn">
              <i class="fas fa-edit"></i> Redaguoti
            </button>
          </div>
          <div v-if="userData" class="user-details">
            <div v-if="isEditingProfile">
              <div class="detail-item">
                <label>El. paštas:</label>
                <input disabled type="email" v-model="editableUserData.email" />
              </div>
              <div class="detail-item">
                <label>Vardas:</label>
                <input type="text" v-model="editableUserData.first_name" />
              </div>
              <div class="detail-item">
                <label>Pavardė:</label>
                <input type="text" v-model="editableUserData.last_name" />
              </div>
              <div class="detail-item">
                <label>Gimimo diena:</label>
                <Datepicker style="width: 100%" v-model="editableUserData.birthday" :locale="locale"
                  :style="customStyles" />
              </div>
              <button @click="saveProfile()" class="save-btn">Išsaugoti</button>
            </div>
            <div v-else>
              <div class="detail-item">
                <label>El. paštas:</label>
                <span>{{ userData.email }}</span>
              </div>
              <div class="detail-item">
                <label>Vardas:</label>
                <span>{{ userData.first_name }}</span>
              </div>
              <div class="detail-item">
                <label>Pavardė:</label>
                <span>{{ userData.last_name }}</span>
              </div>
              <div class="detail-item">
                <label>Gimimo diena:</label>
                <span>{{ formattedBirthday }}</span>
              </div>
            </div>
          </div>
          <div v-else>Jūsų duomenys kraunami, prašome palaukti...</div>
        </div>

        <div class="profile-section">
          <div class="section-header">
            <h2 class="title">Kontaktinė informacija</h2>
            <button @click="editContactInfo()" class="edit-btn">
              <i class="fas fa-edit"></i> {{ contactButtonText }}
            </button>
          </div>
          <div v-if="userData" class="contact-info">
            <div v-if="isEditingContactInfo">
              <div class="detail-item">
                <label>Telefono nr:</label>
                <input type="text" v-model="editableUserData.phone_number" />
              </div>
              <div class="detail-item">
                <label>Miestas:</label>
                <input type="text" v-model="editableUserData.city" />
              </div>
              <div class="detail-item">
                <label>Adresas:</label>
                <input type="text" v-model="editableUserData.address" />
              </div>
              <div class="detail-item">
                <label>Pašto kodas:</label>
                <input type="text" v-model="editableUserData.postal_code" />
              </div>
              <button @click="saveContactInfo()" class="save-btn">
                Išsaugoti
              </button>
            </div>
            <div v-else>
              <div class="detail-item">
                <label>Telefono nr:</label>
                <span>{{ userData.phone_number || "-" }}</span>
              </div>
              <div class="detail-item">
                <label>Miestas:</label>
                <span>{{ userData.city || "-" }}</span>
              </div>
              <div class="detail-item">
                <label>Adresas:</label>
                <span>{{ userData.address || "-" }}</span>
              </div>
              <div class="detail-item">
                <label>Pašto kodas:</label>
                <span>{{ userData.postal_code || "-" }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="activeTab === 'orders'">
        <OrderTab/>
      </div>

      <div v-if="activeTab === 'favorites'">
        <FavoritesTab/>
        <!-- <div class="profile-section">
          <h2 style="margin-bottom: 2rem">Patikusios prekės</h2>
          <div v-if="favorites.length === 0">
            <p>Čia rodomos jūsų patikusios prekės.</p>
          </div>
          <div v-else>
            <ul class="favorite-product-container">
              <li v-for="favorite in favorites" :key="favorite.product_id" class="favorite-item-row row">


                <img :src="favorite.main_image" @click="goToProductPage(favorite.product_id)" alt="Product image"
                  class="favorite-thumbnail col-6" />
                <div class="favorite-info col-7" style="text-align: left">
                  <div class="favorite-name" @click="goToProductPage(favorite.product_id)">{{ favorite.name }}</div>
                  <div class="favorite-price" @click="goToProductPage(favorite.product_id)">{{ favorite.price }} €</div>

                  <button style="margin-top: -2rem !important; margin-left: 80%" id="favBtn" class="favor-btn col-3"
                    :class="{ liked: true }" @click="unfavorProduct(favorite.product_id)">
                    <i class="fas fa-heart"></i>
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </div> -->
      </div>
    </div>
    
  <div style="display: flex; align-items: flex-end; justify-self: flex-end" class="mb-2">
    <button @click="logout" class="logout-btn">Atsijungti</button>
  </div>
  </div>
</template>

<script>
import axios from "axios";
import Datepicker from "vue3-datepicker";
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
import { lt } from "date-fns/locale"; // Import the Lithuanian locale
import { ref } from "vue";
import { format } from "date-fns"; // Importing from date-fns for date formatting (optional)
import OrderTab from "./OrderTab.vue";
import FavoritesTab from "./FavoritesTab.vue";
import EventBus from "@/eventBus";

export default {
  name: "UserProfileComponent",
  components: {
    Datepicker, // Register Datepicker component
    OrderTab,
    FavoritesTab
  },
  data() {
    return {
      activeTab: "profile",
      favorites: [],
      userData: null,
      editableUserData: { birthday: null },
      isEditingProfile: false,
      isEditingContactInfo: false,
      locale: lt, // Use correct locale object from date-fns
      from: new Date(), // 100 years ago from today
      to: new Date(), // Today
      picked: ref(new Date()),
      customStyles: {
        "--vdp-bg-color": "#ffffff",
        "--vdp-text-color": "#000000",
        "--vdp-box-shadow":
          "0 4px 10px 0 rgba(128, 144, 160, 0.1), 0 0 1px 0 rgba(128, 144, 160, 0.81)",
        "--vdp-border-radius": "3px",
        "--vdp-heading-size": "2.5em",
        "--vdp-heading-weight": "bold",
        "--vdp-heading-hover-color": "#eeeeee",
        "--vdp-arrow-color": "currentColor",
        "--vdp-elem-color": "currentColor",
        "--vdp-disabled-color": "#d5d9e0",
        "--vdp-hover-color": "#ffffff",
        "--vdp-hover-bg-color": "#2cb6d2",
        "--vdp-selected-color": "#ffffff",
        "--vdp-selected-bg-color": "#2cb6d2",
        "--vdp-current-date-outline-color": "#888888",
        "--vdp-current-date-font-weight": "bold",
        "--vdp-elem-font-size": "0.8em",
        "--vdp-elem-border-radius": "3px",
        "--vdp-divider-color": "#d5d9e0",
      },
    };
  },
  watch: {
    $route(to, from) {
      if (to.params.selectedTab !== from.params.selectedTab) {
        this.setActiveTab(to.params.selectedTab);
      }
    },
  },
  mounted() {
    const selectedTab = this.$route.params.selectedTab || "profile";
    this.setActiveTab(selectedTab);
    this.fetchUserData();
  },
  methods: {
    setActiveTab(tab) {
      this.activeTab = tab;
      if (tab === "favorites") {
        this.fetchLikedProducts();
      }
    },
    fetchLikedProducts() {
      const token = localStorage.getItem("userToken");
      if (!token) return;

      axios
        .get(`${API_BASE_URL}/product/liked-products`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.favorites = response.data;
        })
        .catch((error) => {
          console.error("Error fetching liked products:", error);
        });
    },
    fetchUserData() {
      const token = localStorage.getItem("userToken");
      if (!token) return;

      axios
        .get(`${API_BASE_URL}/user/profile`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.userData = response.data.userData;
          this.editableUserData = { ...this.userData };
          this.editableUserData.birthday = new Date(
            this.editableUserData.birthday
          );
        })
        .catch((error) => {
          console.error("Error fetching user data", error);
        });
    },
    editUserProfile() {
      this.isEditingProfile = true;
    },
    editContactInfo() {
      this.isEditingContactInfo = true;
    },
    logout() {
      localStorage.removeItem("userToken");
      this.isLoggedIn = false;
      this.$router.push({ name: "Login" });
      EventBus.emit("cartUpdated");
    },
    saveProfile() {
      const token = localStorage.getItem("userToken");
      axios
        .put(`${API_BASE_URL}/user/profile`, this.editableUserData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          this.userData = { ...this.editableUserData };
          this.isEditingProfile = false;
        })
        .catch((error) => {
          console.error("Error saving profile", error);
        });
    },
    saveContactInfo() {
      const token = localStorage.getItem("userToken");
      axios
        .put(`${API_BASE_URL}/user/profile`, this.editableUserData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          this.userData = { ...this.editableUserData }; // Save the updated data
          this.isEditingContactInfo = false; // Exit edit mode
        })
        .catch((error) => {
          console.error("Error saving contact info", error);
        });
    },
    goToProductPage(productId) {
      this.$router.push({ name: "Product", params: { id: productId } });
    },
    goToOrders() {
      this.$router.push({
        name: "userProfile",
        params: { selectedTab: "orders" },
      });
      this.setActiveTab("orders");
    },
    goToFavorites() {
      this.$router.push({
        name: "userProfile",
        params: { selectedTab: "favorites" },
      });
      this.setActiveTab("favorites");
    },
    goToProfile() {
      this.$router.push({
        name: "userProfile",
        params: { selectedTab: "profile" },
      });
      this.setActiveTab("profile");
    },
    unfavorProduct(productId) {
      const token = localStorage.getItem("userToken");
      axios
        .post(
          `${API_BASE_URL}/product/${productId}/like`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          this.favorites = this.favorites.filter(favorite => favorite.product_id !== productId);
        });
    }
  },
  computed: {
    contactButtonText() {
      if (this.userData) {
        // Destructure properties only if userData is not null
        const { phone_number, city, address, postal_code } = this.userData;
        // Check if all fields are filled
        if (phone_number && city && address && postal_code) {
          return "Redaguoti";
        }
      }
      return "Pridėti informacija";
    },
    formattedBirthday() {
      if (!this.userData.birthday) {
        return "-";
      }
      // Use date-fns for formatting
      return format(new Date(this.userData.birthday), "yyyy-MM-dd");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/src/styles/variable";

.title{
  text-align: left;
  width: 50%;
}

.profile-wrapper {
  min-height: 65vh;
  max-width: 900px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #f4f6f9;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 1rem;
    margin: 1rem;
  }
}

.profile-header {
  text-align: center;
  margin-bottom: 2.5rem;

  h1 {
    font-size: 28px;
    color: #333;
    margin-bottom: 0.5rem;

    @media (max-width: 768px) {
      font-size: 22px;
    }
  }

  .subtitle {
    font-size: 16px;
    color: #777;

    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
}

.profile-tabs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  border-bottom: 1px solid #ddd;

  button {
    flex: 1;
    padding: 0.75rem;
    font-size: 16px;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    color: #2cb6d2;
    transition: all 0.3s ease;

    &:hover {
      color: #2589aa;
    }

    &.active {
      border-bottom: 2px solid #2589aa;
      color: #2589aa;
      font-weight: bold;
    }

    @media (max-width: 768px) {
      font-size: 14px;
      padding: 0.5rem;
    }
  }
}

.tab-content {
  margin-top: 2rem;
}

.profile-section {
  background-color: white;
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

  .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;

    h2 {
      font-size: 22px;
      color: #333;

      @media (max-width: 768px) {
        font-size: 18px;
      }
    }

    .edit-btn {
      background-color: transparent;
      border: none;
      color: #2cb6d2;
      font-size: 16px;
      cursor: pointer;
      display: flex;
      align-items: center;

      i {
        margin-right: 0.5rem;
      }

      &:hover {
        color: #2589aa;
      }

      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
  }
}

.user-details,
.contact-info {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .detail-item {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    margin: 1rem;

    label {
      font-weight: 500;
      color: #555;
    }

    span {
      color: #333;
    }

    @media (max-width: 768px) {
      font-size: 14px;
      flex-direction: column;
      align-items: flex-start;

      label,
      span {
        margin-bottom: 0.5rem;
      }
    }
  }
}

.favorite-item-row {
  display: flex;
  align-items: center;
  padding: 0.3rem;
  background-color: #f9f9f9;
  border-bottom: 1px solid gainsboro;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  height: 125px;

  &:hover {
    background-color: #f1f1f1;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  &:active {
    background-color: #e0e0e0;
    transform: scale(0.98);
  }

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
    padding: 1rem;
  }
}

.favorite-thumbnail {
  width: 140px;
  height: 100px;
  object-fit: contain;
  margin-right: 1rem;
  border: 1px solid #ddd;
  background-color: #fff !important;
  border-radius: 4px;
  margin-left: 1rem;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    margin: 0 0 1rem 0;
  }
}

.favorite-info {
  text-align: left;
  margin-left: 1.3rem;

  @media (max-width: 768px) {
    margin-left: 0;
  }
}

.favorite-name {
  font-size: 17px;
  color: #333;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 16px;
  }
}

.favorite-price {
  margin-top: 0.3rem;
  font-size: 15px;
  color: #2cb6d2;

  @media (max-width: 768px) {
    font-size: 14px;
  }
}

.save-btn {
  padding: 8px 20px;
  background-color: #2cb6d2;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 1rem;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #259aa8;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 6px 16px;
  }
}

.logout-btn {
  padding: 8px 20px;
  background-color: #d64141;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: #d64b4b;
  }

  &:active {
    background-color: #c03c3c;
    transform: scale(0.96);
    transition: transform 0.2s ease;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 6px 16px;
  }
}
</style>
