<template>
    <div class="payment-status-container">
        <div v-if="paymentFailed">
            <PaymentFailed :orderId="orderId" />
        </div>
        <div v-else class="loading-container">
            <div class="spinner"></div>
            <p>Mokėjimo atšaukimas vyksta. Prašome palaukti...</p>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import PaymentFailed from './PaymentFailed.vue';

export default {
    name: "PaymentFailedBefore",
    components: {
        PaymentFailed,
    },
    data() {
        return {
            paymentFailed: false,
            orderId: null,
        };
    },
    async created() {
        try {
            const orderId = '6'; // Retrieve order ID dynamically from the query parameters
            const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/payment/cancel`, {
                params: { order_id: orderId },
            });

            if (response.data.message === 'Payment was canceled') {
                this.paymentFailed = true; // Set the state for a failed payment
                this.orderId = response.data.orderId || orderId; // Fallback to the queried orderId
            }
        } catch (error) {
            console.error('Error handling payment cancellation:', error);
            this.paymentFailed = true; // Treat it as a failed payment in case of error
        }
    },
};
</script>

<style scoped>
.payment-status-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 60vh;
    text-align: center;
}

.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.spinner {
    width: 40px;
    height: 40px;
    border: 4px solid rgba(44, 182, 210, 0.2);
    border-top-color: #2cb6d2;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

p {
    font-size: 1.2rem;
    color: #333;
    margin-top: 1rem;
}
</style>
