<template>
    <div v-if="visible" :class="['message-box', type]" class="fade" @click="hideMessage">
      <span>{{ message }}</span>
    </div>
  </template>
  
  <script>
  export default {
    name: "MessageComponent",
    props: {
      message: String,
      type: {
        type: String,
        default: 'error', // Can be 'error', 'warning', or 'success'
      },
    },
    data() {
      return {
        visible: true,
      };
    },
    methods: {
      hideMessage() {
        this.visible = false;
      },
    },
    mounted() {
      setTimeout(() => {
        this.visible = false;
      }, 5000); // Automatically hide after 5 seconds
    },
  };
  </script>
  
  <style scoped>
  .message-box {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    margin: 1rem auto;
    border-radius: 8px;
    font-size: 1rem;
    max-width: 400px;
    color: white;
    cursor: pointer;
    transition: opacity 0.3s ease;
  }
  
  .message-box.error {
    background-color: #f75c4b; /* Red for errors */
  }
  
  .message-box.warning {
    background-color: #f39c12; /* Yellow for warnings */
  }
  
  .message-box.success {
    background-color: #3faf6d; /* Green for success */
  }
  
  .fade {
    opacity: 1;
    transition: opacity 0.5s ease;
  }
  
  .fade-leave-active {
    opacity: 0;
  }
  </style>
  