<template>
    <div v-if="isVisible" class="payment-success-modal">
        <div class="modal-content">
            <!-- Checkmark Icon -->
            <div class="checkmark-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <circle cx="12" cy="12" r="10"></circle>
                    <path d="M9 12l2 2 4-4"></path>
                </svg>
            </div>
            <h2 class="modal-title">Mokėjimas sėkmingas!</h2>
            <p class="modal-message">
                Jūsų užsakymas buvo sėkmingai apmokėtas. Dėkojame, kad pirkote pas mus!
            </p>
            <div class="order-summary">
                <p><strong>Užsakymo numeris:</strong> {{ orderId }}</p>
                <p><strong>Suma:</strong> €{{ totalAmount }}</p>
            </div>
            <button @click="closeModal" class="close-button">Grįžti į pradžią</button>
        </div>
    </div>
</template>

<script>
export default {
    name: "PaymentSuccess",
    props: {
        orderId: {
            type: String,
            required: true,
        },
        totalAmount: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            isVisible: true,
        };
    },
    created() {
        this.clearGuestCart();
        this.trackPurchase();
    },
    methods: {
        trackPurchase() {
            /* global gtag */
            if (typeof gtag === "function") {
                gtag("event", "purchase", {
                    transaction_id: this.orderId, // Unique order ID
                    value: this.totalAmount,     // Total purchase amount
                    currency: "EUR",             // Set the currency
                });
                console.log(`Purchase event sent: Order ID = ${this.orderId}, Total = €${this.totalAmount}`);
            } else {
                console.warn("Google Analytics (gtag) not initialized.");
            }
        },
        closeModal() {
            this.isVisible = false;
            this.$router.push('/');
        },
        clearGuestCart() {
            if (localStorage.getItem("guestCart")) {
                localStorage.removeItem("guestCart");
            }
        },
    },
};
</script>

<style scoped>
.payment-success-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 8px;
    text-align: center;
    max-width: 500px;
    width: 90%;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    position: relative;
}

.checkmark-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
}

.checkmark-icon svg {
    width: 48px;
    height: 48px;
    color: #0dcaf0;
}

.modal-title {
    font-size: 1.8rem;
    margin-bottom: 1rem;
}

.modal-message {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 1.5rem;
}

.order-summary {
    font-size: 1rem;
    color: #555;
    margin-bottom: 1.5rem;
}

.close-button {
    background-color: #2cb6d2;
    color: #ffffff;
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.close-button:hover {
    background-color: #249bb0;
}
</style>