export default function getAuthHeaders() {
    const token = localStorage.getItem("userToken");
  
    if (token) {
      return { Authorization: `Bearer ${token}` };
    } else {
      const sessionId = localStorage.getItem("sessionId") || crypto.randomUUID();
      
      localStorage.setItem("sessionId", sessionId);
  
      return { "X-Session-Id": sessionId };
    }
  }
  